import { Checkout, CheckoutProps, Layout } from 'components';
import {
  CheckoutProvider,
  EventMessage,
  EventName,
  InitParams,
  Params,
  getErrorMessage,
  getInitalState,
  toErrorMessage,
  toEventMessage,
  toParams,
  toSearchParams,
  useSendMessage,
} from 'lib';
import React, { useState } from 'react';

export interface AppProps {
  elementId: string;
}
export const App: React.FC<AppProps> = ({ elementId }) => {
  const [params, setParams] = useState<Params>();
  const [initParams, setInitParams] = useState<InitParams>();
  const [waitForInit, setWaitForInit] = useState(true);
  const [error, setError] = useState('');

  const sendMessage = useSendMessage();
  const checkoutRef: CheckoutProps['checkoutRef'] = React.useRef(null);

  const onError = React.useCallback(
    (error: unknown) => {
      const message = getErrorMessage(error);
      sendMessage(toErrorMessage(message, elementId));
      return message;
    },
    [sendMessage, elementId],
  );

  const onInitMessage = React.useCallback((message: EventMessage) => {
    setInitParams(message.event.eventData);
    setWaitForInit(false);
  }, []);

  const onEventMessage = React.useCallback(
    (message: EventMessage) => {
      message.event.eventName === EventName.Init && onInitMessage(message);
    },
    [onInitMessage],
  );

  const onMessage = React.useCallback(
    ({ data }: MessageEvent) => {
      try {
        const message = JSON.parse(data);
        if (message?.service === 'Limepay') {
          message.event && onEventMessage(message as EventMessage);
        }
      } catch (error) {}
      checkoutRef.current?.onMessage(data);
    },
    [onEventMessage],
  );

  React.useEffect(() => {
    setError('');
    try {
      const _params = toParams(toSearchParams(window.location.search));
      setParams(_params);
      setWaitForInit(_params.waitForInit || false);

      window.addEventListener('message', onMessage, false);
      _params.waitForInit && sendMessage(toEventMessage(EventName.Ready, _params.elementId));
      return () => window.removeEventListener('message', onMessage);
    } catch (error) {
      setError(onError(error));
    }
  }, [onError, onMessage, sendMessage]);

  if (error) return <Layout error={error} pending />;
  if (!params || waitForInit) return <Layout pending />;

  return (
    <CheckoutProvider initalState={getInitalState(params, initParams)}>
      <Checkout checkoutRef={checkoutRef} />
    </CheckoutProvider>
  );
};
