import {
  GetCheckoutConfigResponse,
  GetCustomerResponse,
  SendCustomerVerification,
  VeryGoodSecurityPaymentMethodToken,
} from 'app';
import firebase from 'firebase/app';

import { Vault } from '@april/lib-ui';

import { ALLOW_CURRENCIES, ALLOW_VERSIONS, CheckoutStep } from './';

export type Version = typeof ALLOW_VERSIONS[number];

export type Currency = typeof ALLOW_CURRENCIES[number];

export type PaymentType = 'paycard' | 'payplan';

export type PayPlanVariant = 'payplan_fortnight_0_100' | 'payplan_fortnight_25_25_25_25';

export enum CardUsageScope {
  delegate = 'ScopeDelegate',
  organisation = 'ScopeOrganisation',
}

export type CustomisedInstalmentSchedule = Array<{
  instalment: number;
  at: string;
}>;

export type ProposedSchedule = Array<{
  at: string;
  amount: number;
}>;

export interface PayPlanData {
  customerId: string;
  merchantId: string;
  createdAt: string;
  planAmount: {
    amount: number;
    currency: Currency;
  };
  initialPayment: number;
  instalmentLength: number;
  instalmentFrequency: string;
  customisedInstalmentSchedule: CustomisedInstalmentSchedule;
  proposedSchedule: ProposedSchedule;
}

export interface CustomerEligibility {
  customerId: string;
  merchantId: string;
  planAmount: {
    amount: number;
    currency: Currency;
  };
  requestThreeDSecure: boolean;
  offerPayPlan?: {
    isCounterOffer: boolean;
    payPlanData: PayPlanData;
    payPlanDataVerificationToken: string;
  };
  mitigations?: [];
  message?: string;
  createdAt: string;
}

export type CardPaymentSourceBrand =
  | ''
  | 'American Express'
  | 'MasterCard'
  | 'Visa'
  | 'Diners Club'
  | 'Discover'
  | 'UnionPay'
  | 'Maestro'
  | 'Elo'
  | 'Mir'
  | 'Hiper'
  | 'Hipercard';

export interface CardPaymentSource {
  cardPaymentSourceId: string;
  last4: string;
  cardBin?: string;
  brand: CardPaymentSourceBrand;
  expMonth: number;
  expYear: number;
  country: string;
}

export type SearchParams = { [key: string]: string | undefined };

export type Params = Readonly<{
  apiBaseUri?: string;
  publicKey: string;
  currency: Currency;
  paymentType: PaymentType;
  email: string;
  phoneNo: string;
  customerFirstName?: string;
  customerMiddleName?: string;
  customerLastName?: string;
  customerResidentialAddress?: string;
  showPayNow?: boolean;
  showPayPlanSubmit?: boolean;
  hideFullPayOption?: boolean;
  hidePayLaterOption?: boolean;
  platform: string;
  emailAndSmsCheckoutTokenVerified?: boolean;
  checkoutCSSOverride?: string;
  waitForInit?: boolean;
  elementId: string;
  version: Version;
  paycardAmount: number;
  paycardSurchargeAmount: number;
  payplanAmount: number;
  payplanSurchargeAmount: number;
  showCardOnly?: boolean;
  showCardSubmit?: boolean;
  hideSavedCards?: boolean;
  isB2B?: boolean;
}>;

export type InitParams = Readonly<{
  customerToken?: string;
}>;

export type Config = Readonly<GetCheckoutConfigResponse>;

export interface UserClaims {
  email: string;
  limepay: {
    customerAccountId: string;
    customerId: string;
    merchantId: string;
  };
}

export type CurrentUser = GetCustomerResponse;

export interface State {
  params: Params;
  initParams?: InitParams;
  config?: Config;
  paymentType: PaymentType;
  vaultCard?: Vault;
  vaultCvc?: Vault;
  firebaseAuth: firebase.auth.Auth | null;
  error: string;
  customerId: string;
  minFirstInstalmentAmount: number;
  emailVerificationCode: string;
  phoneVerificationCode: string;
  isTermsAgreed: boolean;
  waitForInit: boolean;
  currentUser?: CurrentUser;
  emailVerification: SendCustomerVerification['emailVerification'];
  phoneVerification: SendCustomerVerification['phoneVerification'];
  customerEligibility: CustomerEligibility | null;
  customerEligibilityToken: string | null;
  veryGoodSecurityPaymentMethodToken?: VeryGoodSecurityPaymentMethodToken;
  cardPaymentSourceId: string | null;
  cardPaymentSources: CardPaymentSource[];
  checkoutStep: CheckoutStep;
  b2bCardUsageScope: CardUsageScope | null;
  veryGoodSecurityCardCvcToken: string;
}
