import { Config, Params, State, getInitalState } from 'lib';

const API_URL = process.env.REACT_APP_API_HOST || '';
const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY || '';
const MERCHANT_ID = process.env.REACT_APP_MERCHANT_ID || '';
const AUTH_API_KEY = process.env.REACT_APP_AUTH_API_KEY || '';
const AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN || '';
const TENANT_ID = process.env.REACT_APP_TENANT_ID || '';
const GOOGLE_PROJECT_API_KEY = process.env.REACT_APP_GOOGLE_PROJECT_API_KEY || '';
const VGS_VAULT_ID = process.env.REACT_APP_VGS_VAULT_ID || '';
const VGS_VAULT_ENVIRONMENT = process.env.REACT_APP_VGS_VAULT_ENVIRONMENT || '';

export const defaultParams: Params = {
  apiBaseUri: API_URL,
  publicKey: PUBLIC_KEY,
  currency: 'AUD',
  paymentType: 'paycard',
  email: '',
  phoneNo: '',
  platform: 'custom',
  showPayNow: true,
  showPayPlanSubmit: true,
  elementId: 'limepay-cont',
  version: '2.1.0',
  paycardAmount: 10000,
  paycardSurchargeAmount: 100,
  payplanAmount: 10000,
  payplanSurchargeAmount: 100,
};

export const defaultConfig: Config = {
  apiBaseUri: API_URL,
  tcUrl: '',
  privacyUrl: '',
  merchantId: MERCHANT_ID,
  authApiKey: AUTH_API_KEY,
  authDomain: AUTH_DOMAIN,
  tenantId: TENANT_ID,
  googleProjectApiKey: GOOGLE_PROJECT_API_KEY,
  veryGoodSecurityVaultId: VGS_VAULT_ID,
  veryGoodSecurityVaultEnvironment: VGS_VAULT_ENVIRONMENT as Config['veryGoodSecurityVaultEnvironment'],
  maxPayPlanAmountUnconfirmedCustomers: 1000000,
  maxPayPlanAmountConfirmedCustomers: 2000000,
  minPayPlanAmount: 500,
  displayCustomerSavedPaymentMethodsInCheckout: true,
  allowHideFullPay: true,
  merchantTradingCountry: 'AU',
  payPlanVariant: 'payplan_fortnight_25_25_25_25',
  supportedCustomerTypes: ['consumercustomertype'],
  merchantBusinessName: 'merchantBusinessName',
  merchantBusinessDisplayName: 'merchantBusinessName',
};

export const defaultState: State = { ...getInitalState(defaultParams), config: defaultConfig };
