import dayjs from 'dayjs';
import queryString from 'query-string';

import { Config, Currency, Params, PaymentType, SearchParams, Version } from './';

export const toCurrency = (amount: number) => (amount / 100).toFixed(2);

export const toCurrencySymbol = (currency: Currency) => '$';

export const validateEmail = (email: any): boolean => {
  /* eslint-disable no-useless-escape */
  // eslint-disable-next-line prettier/prettier
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return email ? re.test(email) : false;
};

export const validateFirstInstalmentAmount = ({
  firstInstalmentAmount,
  minFirstInstalmentAmount,
  amount,
}: {
  firstInstalmentAmount: number;
  minFirstInstalmentAmount: number;
  amount: number;
}): string => {
  let validationResult = '';

  if (firstInstalmentAmount < minFirstInstalmentAmount) {
    validationResult = 'Amount is too low';
  } else if (firstInstalmentAmount >= amount) {
    validationResult = 'Amount is too high';
  }

  return validationResult;
};

export const isNumeric = (value: any): boolean => !isNaN(value - parseFloat(value));

export const validateNonEmptyStr = (str: string): boolean => !!str.trim().length;

export enum CheckoutStep {
  PaymentOption,
  UserVerification,
  IdentityVerification,
  PlanReview,
}

export const ALLOW_VERSIONS = ['2.1.0', '2.0.0'] as const;

export const ALLOW_CURRENCIES = ['AUD', 'NZD'] as const;

export const instalmentNames = ['first', 'second', 'third', 'fourth'];

export const getPayPlanVariantData = (params: Params, config: Config) => {
  const isDeferred = config.payPlanVariant === 'payplan_fortnight_0_100';
  const instalmentFrequency = 'Fortnightly';

  if (isDeferred) {
    return {
      initialPayment: 0,
      instalmentLength: 2,
      instalmentFrequency,
    };
  }

  const instalmentLength = 4;
  const instalmentAmount = Math.floor(params.payplanAmount / instalmentLength);
  const initialPayment = params.payplanAmount - instalmentAmount * (instalmentLength - 1);

  return {
    initialPayment,
    instalmentLength,
    instalmentFrequency,
  };
};

const currencyNumberFormat = (value: number, centsOptional?: boolean) => {
  var amt = Math.floor(value / 100) * 100;

  if (centsOptional && value === amt) {
    return (value / 100).toFixed(0);
  }

  return (value / 100).toFixed(2);
};

export const currencyFormat = (value: number, currency: string, opt?: { centsOptional: boolean }) => {
  var centsOptional = !!opt?.centsOptional;
  return currencyNumberFormat(value, centsOptional);
};

export const getActivePayOption = (paymentType: string, hidePayOption: string): PaymentType => {
  var payOptions = ['payplan', 'paycard'];

  if (hidePayOption === 'paycard') {
    return 'payplan';
  } else if (hidePayOption === 'payplan') {
    return 'paycard';
  } else if (payOptions.includes(paymentType)) {
    return paymentType as PaymentType;
  }

  return 'paycard';
};

export const checkIsValidateDate = (date: string, format: string): boolean => {
  return dayjs(date, format).format(format) === date;
};

export const toSearchParams = (search: string) => queryString.parse(search) as SearchParams;

export const toBoolean = (value: string = '') =>
  ['true', 'false', '0', '1'].includes(value.toLowerCase()) ? ['true', '1'].includes(value.toLowerCase()) : undefined;

export const toVersion = (version: Version) => (ALLOW_VERSIONS.includes(version) ? version : '2.0.0');

export const toParams = (searchParams: SearchParams): Params => {
  const amount = searchParams.amount ? Number(searchParams.amount) : 0;
  const paramsPaymentType = searchParams.paymentType || 'paycard';
  const paramsHidePayOption = toBoolean(searchParams.hidePayLaterOption)
    ? 'payplan'
    : toBoolean(searchParams.hideFullPayOption)
    ? 'paycard'
    : '';
  const initPaymentType = getActivePayOption(paramsPaymentType, paramsHidePayOption);
  const paymentType = initPaymentType || 'paycard';
  const email = searchParams.email ? searchParams.email : '';
  const phoneNo = searchParams.phoneNo ? searchParams.phoneNo : '';
  const showCardOnly = toBoolean(searchParams.showCardOnly);

  if (!searchParams.publicKey) {
    throw Error('Incorrect public key');
  }
  if (!showCardOnly) {
    if (!amount) {
      throw Error('Incorrect checkout amount');
    }
    if (!searchParams.currency) {
      throw Error(`Currency is required`);
    }
    if (!ALLOW_CURRENCIES.includes(searchParams.currency as Currency)) {
      throw Error(`Currency ('${searchParams.currency}') is not supported`);
    }
  }

  const paycardAmount = searchParams.paycardAmount ? Number(searchParams.paycardAmount) : amount;
  const payplanAmount = searchParams.payplanAmount ? Number(searchParams.payplanAmount) : amount;

  return {
    publicKey: searchParams.publicKey,
    currency: searchParams.currency as Currency,
    paymentType,
    email,
    phoneNo,
    customerFirstName: searchParams.customerFirstName,
    customerMiddleName: searchParams.customerMiddleName,
    customerLastName: searchParams.customerLastName,
    customerResidentialAddress: searchParams.customerResidentialAddress,
    showPayNow: toBoolean(searchParams.showPayNow),
    showPayPlanSubmit: toBoolean(searchParams.showPayPlanSubmit),
    hideFullPayOption: toBoolean(searchParams.hideFullPayOption),
    hidePayLaterOption: toBoolean(searchParams.hidePayLaterOption),
    platform: searchParams.platform || 'custom',
    emailAndSmsCheckoutTokenVerified: toBoolean(searchParams.emailAndSmsCheckoutTokenVerified),
    checkoutCSSOverride: searchParams.checkoutCSSOverride,
    waitForInit: toBoolean(searchParams.waitForInit),
    elementId: searchParams.elementId ?? '',
    version: toVersion(searchParams.version as Version),
    paycardAmount,
    paycardSurchargeAmount: Math.max(paycardAmount - amount, 0),
    payplanAmount,
    payplanSurchargeAmount: Math.max(payplanAmount - amount, 0),
    showCardOnly,
    showCardSubmit: toBoolean(searchParams.showCardSubmit),
    hideSavedCards: toBoolean(searchParams.hideSavedCards),
    isB2B: toBoolean(searchParams.isB2B),
  };
};

export const checkPayPlanAmountEligibility = (params: Params, config: Config) => {
  const { payplanAmount: amount } = params;
  const { minPayPlanAmount, maxPayPlanAmountUnconfirmedCustomers, maxPayPlanAmountConfirmedCustomers } = config;
  const maxPayPlanAmount = Math.max(maxPayPlanAmountUnconfirmedCustomers, maxPayPlanAmountConfirmedCustomers);
  const minPayPlanAmountEligibility = amount >= minPayPlanAmount;
  const maxPayPlanAmountEligibility = amount <= maxPayPlanAmount;
  const payPlanAmountEligibility = minPayPlanAmountEligibility && maxPayPlanAmountEligibility;

  return {
    minPayPlanAmount,
    maxPayPlanAmount,
    minPayPlanAmountEligibility,
    maxPayPlanAmountEligibility,
    payPlanAmountEligibility,
  };
};

export const isB2B = (params: Params, config: Config) => {
  if (
    (params.isB2B === true && !config.supportedCustomerTypes.includes('organisationcustomertype')) ||
    (params.isB2B === false && !config.supportedCustomerTypes.includes('consumercustomertype'))
  ) {
    throw Error(`Customer type not supported: ${params.isB2B ? 'B2B' : 'B2C'}`);
  }

  return (
    params.isB2B || config.supportedCustomerTypes.every((CustomerType) => CustomerType === 'organisationcustomertype')
  );
};
