import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { App } from 'app';
import { toSearchParams } from 'lib/utils';
import React from 'react';
import ReactDOM from 'react-dom';

const { elementId } = toSearchParams(window.location.search);

ReactDOM.render(
  <React.StrictMode>
    <App elementId={elementId || ''} />
  </React.StrictMode>,
  document.getElementById('root'),
);
