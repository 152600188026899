import { getApiHostEnv, getHost } from 'lib';

export const loadScript = async (src: string): Promise<void> =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.onload = () => resolve();
    script.onerror = () => reject('Failed to load KYC script');
    script.src = src;
    document.head.appendChild(script);
  });

export const loadKycScript = async (apiBaseUri: string) => {
  const host = getHost(getApiHostEnv(apiBaseUri));

  const identity = `https://identity.${host}/dist/identity.min.js`;

  await loadScript(identity);
};
