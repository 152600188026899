import { cx } from 'emotion';
import {
  EventName,
  Params,
  currencyFormat,
  isNumeric,
  toEventMessage,
  useInitialPaymentChange,
  useSendMessage,
  validateFirstInstalmentAmount,
} from 'lib';
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

export interface InitialPaymentProps {
  params: Params;
  firstInstalmentAmount: number;
  minFirstInstalmentAmount: number;
  isFirstPaymentEdit: boolean;
  setIsFirstPaymentEdit(edit: boolean): void;
}

export const InitialPayment: React.FC<InitialPaymentProps> = ({
  params,
  firstInstalmentAmount,
  minFirstInstalmentAmount,
  setIsFirstPaymentEdit,
}) => {
  const sendMessage = useSendMessage();
  const onInitialPaymentChange = useInitialPaymentChange();

  const firstInstalmentAmountFormatted = currencyFormat(firstInstalmentAmount, params.currency);
  const [initialPaymentAmount, setInitialPaymentAmount] = React.useState<string>(firstInstalmentAmountFormatted);
  const [initialPaymentError, setInitialPaymentError] = React.useState<string>('');
  const [initialPaymentAmountError, setInitialPaymentAmountError] = React.useState<string>('');

  const minFirstInstalmentAmountFormatted = currencyFormat(minFirstInstalmentAmount, params.currency);

  const handleFirstInstalmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const value = e.target.value;
    const firstInstalmentAmount = parseFloat(value) * 100;

    if (isNumeric(value) || value === '') {
      setInitialPaymentAmount(value);
    }

    const validationResult = validateFirstInstalmentAmount({
      firstInstalmentAmount,
      minFirstInstalmentAmount,
      amount: params.payplanAmount,
    });
    setInitialPaymentAmountError(validationResult);
    setInitialPaymentError('');
  };

  const handleFirstInstalmentChangeConfirm = React.useCallback(
    async (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      const amount = parseFloat(initialPaymentAmount) * 100;
      if (initialPaymentAmountError) return false;
      const requestPayload = { amount, isFirstInstalmentChange: true };
      const { customerError } = await onInitialPaymentChange(requestPayload);

      if (customerError) {
        setInitialPaymentError(customerError);
        return false;
      }

      sendMessage(
        toEventMessage(EventName.FirstPaymentAmountChanged, params.elementId, {
          previousFirstPaymentAmount: firstInstalmentAmount,
          updatedFirstPaymentAmount: amount,
          currency: params.currency,
        }),
      );

      setIsFirstPaymentEdit(false);
    },
    [
      params,
      initialPaymentAmount,
      firstInstalmentAmount,
      onInitialPaymentChange,
      setIsFirstPaymentEdit,
      initialPaymentAmountError,
      sendMessage,
    ],
  );

  const handleFirstInstalmentChangeCancel = React.useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      setIsFirstPaymentEdit(false);
    },
    [setIsFirstPaymentEdit],
  );

  return (
    <div id="lpInitialPaymentCont">
      <div className="three-p btop lpFirstInstAmountUpdateCont">
        <div className="form-inputs">
          <h1 className="title-h1">Today’s Payment</h1>
          <p className="mb-1 small-text pt-3 pt-sm-0 color-grey">
            How much would you like to pay today? (Minimum&nbsp;
            <span className="lpMinFirstInstalmentAmountPH">{minFirstInstalmentAmountFormatted}</span>)
          </p>
          <p className="small-text pt-3 pt-sm-1 pb-3 pb-sm-0 color-grey">
            We may add a couple of cents so your future payments are equal.
          </p>
          <div className="row">
            <div className="col-sm-6">
              <div
                className={cx(
                  'form-item firstInstalmentInputCont',
                  (initialPaymentAmountError || initialPaymentError) && 'error',
                )}
              >
                <label className="custom-select-list">$</label>
                <input
                  className="amount form-input"
                  type="text"
                  name="firstInstalmentAmount"
                  placeholder="Payment Amount"
                  value={initialPaymentAmount}
                  onChange={handleFirstInstalmentChange}
                />
                <div className="lpInitialPaymentErrMsg error-msg">
                  {initialPaymentAmountError || initialPaymentError}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-action text-right pr-2 pt-5 pt-sm-2">
          <a className="lpUserChangesCancelButton link style5" href="#" onClick={handleFirstInstalmentChangeCancel}>
            Cancel
          </a>
          <a
            className="lpFirstInstAmountUpdateButton link style4"
            href="#"
            onClick={handleFirstInstalmentChangeConfirm}
          >
            Update
          </a>
        </div>
      </div>
    </div>
  );
};
