import styled from '@emotion/styled';

export const KycWrapper = styled.div`
  text-align: center;
  background-color: #fff;
`;

export const GoBackBtn = styled.button`
  padding: 9px 10px 13px;
  font-weight: bold;
  display: inline-block;
  color: var(--lp-colors-medium-blue-600);
  font-size: 12px;
  background: transparent;
  border: none;
  cursor: pointer;
`;
