import { CheckoutStep, InitParams, Params, State } from './';

export const getInitalState = (params: Params, initParams?: InitParams): State => {
  return {
    params,
    initParams,
    paymentType: params.paymentType,
    firebaseAuth: null,
    error: '',
    customerId: '',
    minFirstInstalmentAmount: 0,
    emailVerificationCode: '',
    phoneVerificationCode: '',
    isTermsAgreed: false,
    waitForInit: true,
    emailVerification: {
      customerId: '',
      emailAddress: '',
      createdAt: '',
    },
    phoneVerification: {
      customerId: '',
      phoneNumber: '',
      createdAt: '',
    },
    customerEligibility: null,
    customerEligibilityToken: null,
    cardPaymentSourceId: null,
    cardPaymentSources: [],
    checkoutStep: CheckoutStep.PaymentOption,
    b2bCardUsageScope: null,
    veryGoodSecurityCardCvcToken: '',
  };
};

export enum ActionType {
  Update,
}

export interface Action<T, P> {
  type: T;
  payload: P;
}

export type UpdateAction = Action<ActionType.Update, Partial<State>>;
export const updateAction = (payload: Partial<State>): UpdateAction => ({ type: ActionType.Update, payload });

export type AnyAction = UpdateAction;

export const reducer = (state: State, action: AnyAction) => {
  switch (action.type) {
    case ActionType.Update:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
