import 'firebase/auth';

import {
  CreatePayCardResponse,
  SendCustomerVerification,
  VeryGoodSecurityPaymentMethodToken,
  addCustomerPaymentSource,
  addCustomerPaymentSourceScoped,
  addTemporaryPaymentSource,
  createOrGetCustomer,
  createPayCard,
  createPayCardJWT,
  createPayPlan,
  defaultHeaders,
  getCheckoutConfig,
  getCustomer,
  getPayPlanEligibilityCheck,
  queryCardPaymentSources,
  sendCustomerVerification,
  submitVaultCard,
  submitVaultCvc,
  updateCustomerPaymentSourceCvc,
  verifyCustomer,
} from 'app';
import { PaymentOptionProps } from 'components';
import firebase from 'firebase/app';
import { useCallback, useContext, useRef } from 'react';
import semver from 'semver';

import { VaultCardType, createVault } from '@april/lib-ui';

import {
  AnyMessage,
  CARD_ERROR_MESSAGE,
  CUSTOMER_TOKEN_ERROR_MESSAGE,
  Currency,
  CustomisedInstalmentSchedule,
  DispatchContext,
  GET_PAYPLAN_DATA_DATE_ERROR_MESSAGE,
  GET_PAYPLAN_DATA_ERROR_MESSAGE,
  STATE_ERROR_MESSAGE,
  State,
  StateContext,
  VgsCardError,
  cardCvcId,
  cardCvcOnlyId,
  cardExpiryId,
  cardNumberId,
  checkPayPlanAmountEligibility,
  fieldStyles,
  getVaultHost,
  onVaultError,
  submitCardinalDdcForm,
  toCardPaymentSourceBrand,
  updateAction,
} from './';
import { loadKycScript } from './loadKycScript';
import { logRocketIdentify, logRocketInit, logRocketTrack } from './logrocket';
import { CardPaymentSource, CustomerEligibility, UserClaims } from './types';
import { getPayPlanVariantData, isB2B } from './utils';

export const useCheckoutContext = () => {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);

  if (!state || !dispatch) throw Error('Checkout context is required');

  const update = useCallback((state: Partial<State>) => dispatch(updateAction(state)), [dispatch]);

  return { state, update };
};

export const useMount = () => {
  const { state, update } = useCheckoutContext();
  const { params, initParams } = state;

  const onMount = useCallback(async () => {
    const config = await getCheckoutConfig(params.apiBaseUri || '', params.publicKey);
    config.isB2B = isB2B(params, config);
    await loadKycScript(config.apiBaseUri);

    if (params.paymentType === 'payplan') {
      const { payPlanAmountEligibility } = checkPayPlanAmountEligibility(params, config);
      !payPlanAmountEligibility && update({ paymentType: 'paycard' });
    }

    const initializeApp = async (options: Object) => {
      try {
        await firebase.app().delete();
      } catch (error) {}
      return firebase.initializeApp(options);
    };

    const [firebaseApp] = await Promise.all([
      initializeApp({
        apiKey: config.authApiKey,
        authDomain: config.authDomain,
      }),
    ]);

    const firebaseAuth = firebaseApp.auth();
    firebaseAuth.tenantId = config.tenantId;
    if (initParams?.customerToken) {
      const userCredential = await firebaseAuth.signInWithCustomToken(initParams?.customerToken);
      const idTokenResult = await userCredential.user?.getIdTokenResult();

      if (!idTokenResult) throw Error(CUSTOMER_TOKEN_ERROR_MESSAGE);

      const claims = idTokenResult.claims as unknown as UserClaims;
      const customerId = claims.limepay.customerId;
      const currentUser = await getCustomer(config.apiBaseUri, customerId, idTokenResult.token);

      update({
        currentUser,
        customerId,
      });

      if (!params.hideSavedCards && config.displayCustomerSavedPaymentMethodsInCheckout) {
        const cardPaymentSources = await queryCardPaymentSources(config.apiBaseUri, customerId, idTokenResult.token);
        update({
          cardPaymentSources,
        });
      }
    }

    const { initialPayment } = getPayPlanVariantData(params, config);
    update({
      config,
      firebaseAuth,
      minFirstInstalmentAmount: initialPayment,
    });

    logRocketInit(async (sessionUrl) => {
      defaultHeaders['Logrocket-Session-Url'] = sessionUrl;
      logRocketIdentify(firebaseAuth);
      const config = await getCheckoutConfig(params.apiBaseUri || '', params.publicKey); // track config request
      logRocketTrack({
        merchantId: config.merchantId,
        marketplaceId: config.marketplaceId,
        sessionCorrelationId: defaultHeaders['Session-Correlation-Id'],
      });
    });
  }, [params, initParams, update]);

  return onMount;
};

export const useSendMessage = () => {
  const sendMessage = useCallback(
    (message: AnyMessage): void => window.parent.postMessage(JSON.stringify(message), '*'),
    [],
  );
  return sendMessage;
};

export const useMountVaultCard = () => {
  const { update } = useCheckoutContext();

  const onMountVaultCard = useCallback<NonNullable<PaymentOptionProps['onMountVaultCard']>>(
    async (onEvent) => {
      const vaultHost = await getVaultHost();

      const [vaultCard, vaultCvc] = await Promise.all([
        createVault({
          vaultHost,
          fields: {
            cardNumber: { elementId: cardNumberId, fieldStyles, inputProps: { placeholder: '1234 1234 1234 1234' } },
            expiryDate: { elementId: cardExpiryId, fieldStyles },
            cardCvc: { elementId: cardCvcId, fieldStyles },
          },
          onEvent,
          onError: onVaultError,
        }),
        createVault({
          vaultHost,
          fields: {
            cardCvc: { elementId: cardCvcOnlyId, fieldStyles },
          },
          onEvent,
          onError: onVaultError,
        }),
      ]);

      update({ vaultCard, vaultCvc });
    },
    [update],
  );

  return onMountVaultCard;
};

export const useCardTypeErrorCheck = () => {
  const { state } = useCheckoutContext();
  const { config } = state;

  const onCardTypeErrorCheck = useCallback(
    (cardType?: VaultCardType | null): string | null => {
      if (!cardType || !config?.allowedCardBrands) return null;

      const cardBrand = toCardPaymentSourceBrand(cardType);

      if (cardBrand && !config.allowedCardBrands.includes(cardBrand)) return `${cardBrand} not supported`;

      return null;
    },
    [config],
  );

  return onCardTypeErrorCheck;
};

export const useTokeniseCard = () => {
  const { state } = useCheckoutContext();
  const { params, vaultCard } = state;

  const onCardTypeErrorCheck = useCardTypeErrorCheck();

  const tokeniseVaultCard = useCallback(async () => {
    if (!params || !vaultCard) throw Error(STATE_ERROR_MESSAGE);

    const formError = onCardTypeErrorCheck(vaultCard.fields.cardNumber?.state.cardType);
    if (formError) return { cardError: { formError } };

    return submitVaultCard(vaultCard, params.publicKey);
  }, [params, vaultCard, onCardTypeErrorCheck]);

  return tokeniseVaultCard;
};

export const useTokeniseCvc = () => {
  const { state } = useCheckoutContext();
  const { params, vaultCvc } = state;

  const tokeniseVaultCvc = useCallback(() => {
    if (!params || !vaultCvc) throw Error(STATE_ERROR_MESSAGE);

    return submitVaultCvc(vaultCvc, params.publicKey);
  }, [params, vaultCvc]);

  return tokeniseVaultCvc;
};

export const useCardSubmit = () => {
  const { state } = useCheckoutContext();
  const {
    params,
    config,
    customerId,
    firebaseAuth,
    currentUser,
    b2bCardUsageScope,
    cardPaymentSourceId,
    cardPaymentSources,
  } = state;

  const tokeniseCard = useTokeniseCard();
  const tokeniseCvc = useTokeniseCvc();

  const onCardSubmit = useCallback(async (): Promise<{
    cardError?: VgsCardError;
    cardPaymentSource?: CardPaymentSource;
  }> => {
    if (!config || !firebaseAuth) throw Error(STATE_ERROR_MESSAGE);

    const useSavedCard = cardPaymentSources.length > 0 && !!cardPaymentSourceId;

    let cardPaymentSource: CardPaymentSource | undefined;
    if (useSavedCard) {
      const { cardError, response: vgsCvcToken } = await tokeniseCvc();

      if (cardError) return { cardError };
      if (!vgsCvcToken) throw Error(CARD_ERROR_MESSAGE);

      const customerIdToken = await firebaseAuth.currentUser?.getIdToken();
      if (!customerIdToken) throw Error(CUSTOMER_TOKEN_ERROR_MESSAGE);

      await updateCustomerPaymentSourceCvc(
        config.apiBaseUri,
        customerId,
        customerIdToken,
        cardPaymentSourceId,
        vgsCvcToken.cardCvc,
      );

      cardPaymentSource = cardPaymentSources.find(
        (cardPaymentSource) => cardPaymentSource.cardPaymentSourceId === cardPaymentSourceId,
      );
    } else {
      const { cardError, response: veryGoodSecurityPaymentMethodToken } = await tokeniseCard();

      if (cardError) return { cardError };
      if (!veryGoodSecurityPaymentMethodToken) throw Error(CARD_ERROR_MESSAGE);

      // save card
      if (currentUser) {
        const customerIdToken = await firebaseAuth.currentUser?.getIdToken();
        if (!customerIdToken) throw Error(CUSTOMER_TOKEN_ERROR_MESSAGE);

        if (config.isB2B && b2bCardUsageScope) {
          const { cardPaymentSourceId, brand, last4, expMonth, expYear, country } =
            await addCustomerPaymentSourceScoped(
              config.apiBaseUri,
              customerId,
              customerIdToken,
              veryGoodSecurityPaymentMethodToken,
              b2bCardUsageScope,
            );
          cardPaymentSource = { cardPaymentSourceId, brand, last4, expMonth, expYear, country };
        } else {
          const { cardPaymentSourceId, brand, last4, expMonth, expYear, country } = await addCustomerPaymentSource(
            config.apiBaseUri,
            customerId,
            customerIdToken,
            {
              veryGoodSecurityPaymentMethodToken,
            },
          );
          cardPaymentSource = { cardPaymentSourceId, brand, last4, expMonth, expYear, country };
        }
      } else {
        // anonymous
        const { cardPaymentSourceId, brand, last4, expMonth, expYear, country } = await addTemporaryPaymentSource(
          config.apiBaseUri,
          params.publicKey,
          {
            veryGoodSecurityPaymentMethodToken,
          },
        );
        cardPaymentSource = { cardPaymentSourceId, brand, last4, expMonth, expYear, country };
      }
    }

    return {
      cardPaymentSource,
    };
  }, [
    config,
    cardPaymentSources,
    cardPaymentSourceId,
    customerId,
    firebaseAuth,
    currentUser,
    params.publicKey,
    b2bCardUsageScope,
    tokeniseCard,
    tokeniseCvc,
  ]);

  return onCardSubmit;
};

export const useCardinalDdcSubmit = () => {
  const { state } = useCheckoutContext();
  const { config } = state;

  const onCardinalDdcSubmit = useCallback(
    async (bin?: string | null) => {
      if (!config?.cardinalDdcJwt || !bin) return null;

      return submitCardinalDdcForm(bin, config.cardinalDdcJwt);
    },
    [config],
  );

  return onCardinalDdcSubmit;
};

export const usePayCardSubmit = () => {
  const { state } = useCheckoutContext();
  const {
    params,
    config,
    customerId,
    currentUser,
    firebaseAuth,
    b2bCardUsageScope,
    cardPaymentSourceId,
    cardPaymentSources,
    vaultCard,
  } = state;

  const tokeniseCard = useTokeniseCard();
  const tokeniseCvc = useTokeniseCvc();
  const onCardinalDdcSubmit = useCardinalDdcSubmit();

  const onPayCardSubmit = useCallback(
    async (amount: number, currency: Currency): Promise<{ cardError?: VgsCardError; paymentTokenId?: string }> => {
      if (!config || !firebaseAuth) throw Error(STATE_ERROR_MESSAGE);

      const showSavedCards = cardPaymentSources.length > 0 && !!cardPaymentSourceId;

      const body = {
        paymentAmount: {
          amount,
          currency,
        },
        customerId: customerId || undefined,
      };
      let response: CreatePayCardResponse;
      if (showSavedCards && firebaseAuth) {
        const { cardError, response: vgsCvcToken } = await tokeniseCvc();
        if (cardError) return { cardError };
        if (!vgsCvcToken) throw Error(CARD_ERROR_MESSAGE);

        const customerIdToken = await firebaseAuth.currentUser?.getIdToken();
        if (!customerIdToken) throw Error(CUSTOMER_TOKEN_ERROR_MESSAGE);

        const cardPaymentSource = cardPaymentSources.find((card) => card.cardPaymentSourceId === cardPaymentSourceId);
        const threeDsSessionId = await onCardinalDdcSubmit(cardPaymentSource?.cardBin);

        response = await createPayCardJWT(config.apiBaseUri, customerIdToken, {
          ...body,
          cardPaymentSourceId: cardPaymentSourceId || '',
          cardCvc: vgsCvcToken.cardCvc,
          threeDsSessionId,
        });
      } else {
        const { cardError, response: veryGoodSecurityPaymentMethodToken } = await tokeniseCard();

        if (cardError) return { cardError };
        if (!veryGoodSecurityPaymentMethodToken) throw Error(CARD_ERROR_MESSAGE);

        const threeDsSessionId = await onCardinalDdcSubmit(vaultCard?.fields.cardNumber?.state.bin);

        response = await createPayCard(config.apiBaseUri, params.publicKey, {
          ...body,
          tokenizedPaymentSource: {
            veryGoodSecurityPaymentMethodToken,
          },
          cardCvc: veryGoodSecurityPaymentMethodToken.cardPaymentMethod.cardCvc,
          threeDsSessionId,
        });

        // save card
        if (currentUser) {
          const customerIdToken = await firebaseAuth.currentUser?.getIdToken();
          if (!customerIdToken) throw Error(CUSTOMER_TOKEN_ERROR_MESSAGE);

          if (config.isB2B && b2bCardUsageScope) {
            await addCustomerPaymentSourceScoped(
              config.apiBaseUri,
              customerId,
              customerIdToken,
              veryGoodSecurityPaymentMethodToken,
              b2bCardUsageScope,
            );
          } else {
            await addCustomerPaymentSource(config.apiBaseUri, customerId, customerIdToken, {
              veryGoodSecurityPaymentMethodToken,
            });
          }
        }
      }

      return { paymentTokenId: response.paymentTokenId };
    },
    [
      config,
      cardPaymentSources,
      cardPaymentSourceId,
      customerId,
      currentUser,
      firebaseAuth,
      params.publicKey,
      b2bCardUsageScope,
      vaultCard,
      tokeniseCard,
      tokeniseCvc,
      onCardinalDdcSubmit,
    ],
  );

  return onPayCardSubmit;
};

export const usePayPlanCardSubmit = () => {
  const { update } = useCheckoutContext();

  const tokeniseCard = useTokeniseCard();

  const onPayPlanCardSubmit = useCallback(async (): Promise<{
    cardError?: VgsCardError;
    veryGoodSecurityPaymentMethodToken?: VeryGoodSecurityPaymentMethodToken;
  }> => {
    update({ veryGoodSecurityPaymentMethodToken: undefined });
    const { cardError, response: veryGoodSecurityPaymentMethodToken } = await tokeniseCard();

    if (cardError) return { cardError };
    if (!veryGoodSecurityPaymentMethodToken) throw Error(CARD_ERROR_MESSAGE);

    update({ veryGoodSecurityPaymentMethodToken });
    return { veryGoodSecurityPaymentMethodToken };
  }, [update, tokeniseCard]);

  return onPayPlanCardSubmit;
};

export const useGetCustomerId = () => {
  const { state, update } = useCheckoutContext();
  const { params, config, currentUser } = state;

  const onGetCustomerId = useCallback(
    async (email: string, phoneNo: string) => {
      if (!config) throw Error(STATE_ERROR_MESSAGE);

      let customerId = currentUser?.customerId || '';

      if (!currentUser) {
        customerId = await createOrGetCustomer(config.apiBaseUri, params.publicKey, {
          emailAddress: email,
          phoneNumber: phoneNo,
        });
        update({ customerId });
      }

      return { customerId };
    },
    [params, config, currentUser, update],
  );

  return onGetCustomerId;
};

export const useSendEmailAndPhoneVerificationCodes = () => {
  const { state, update } = useCheckoutContext();
  const { params, config } = state;

  const sendEmailAndPhoneVerificationCodes = useCallback(
    async (email: string, phoneNo: string, customerId: string) => {
      if (!config) throw Error(STATE_ERROR_MESSAGE);

      const { emailVerification, phoneVerification } = await sendCustomerVerification(
        config.apiBaseUri,
        params.publicKey,
        customerId,
        {
          emailAddress: email,
          phoneNumber: phoneNo,
          mode: 'Phone',
        },
      );

      if (!phoneVerification) throw Error('Error sending verification codes');

      update({ emailVerification, phoneVerification });
    },
    [params, config, update],
  );

  return sendEmailAndPhoneVerificationCodes;
};

export const useVerificationCodesSubmit = () => {
  const { state } = useCheckoutContext();
  const { params, config, firebaseAuth } = state;

  const onVerificationCodesSubmit = useCallback(
    async (
      customerId: string,
      emailVerification: SendCustomerVerification['emailVerification'],
      phoneVerification: SendCustomerVerification['phoneVerification'],
      emailVerificationCode: string,
      phoneVerificationCode: string,
    ): Promise<{ customerError?: string; customToken?: string }> => {
      if (!config || !firebaseAuth) throw Error(STATE_ERROR_MESSAGE);

      const { error: verifyCustomerError, customToken } = await verifyCustomer(config.apiBaseUri, params.publicKey, {
        customerId,
        emailVerification,
        phoneVerification,
        emailVerificationCode: emailVerification ? emailVerificationCode : undefined,
        phoneVerificationCode,
      });

      if (!customToken) return { customerError: verifyCustomerError };

      const userCredential = await firebaseAuth.signInWithCustomToken(customToken);
      if (!userCredential) throw Error(CUSTOMER_TOKEN_ERROR_MESSAGE);

      logRocketIdentify(firebaseAuth);

      return { customToken };
    },
    [params, config, firebaseAuth],
  );

  return onVerificationCodesSubmit;
};

export type onInitialPaymentChange = (payload: {
  amount?: number;
  isFirstInstalmentChange?: boolean;
  customisedInstalmentSchedule?: CustomisedInstalmentSchedule;
}) => Promise<{ customerError?: string; customerEligibility?: CustomerEligibility; customerEligibilityToken?: string }>;
export const useInitialPaymentChange = () => {
  const { state, update } = useCheckoutContext();
  const { params, config, customerId, minFirstInstalmentAmount } = state;

  const onInitialPaymentChange = useCallback<onInitialPaymentChange>(
    async ({ amount, customisedInstalmentSchedule, isFirstInstalmentChange }) => {
      if (!config) throw Error(STATE_ERROR_MESSAGE);

      try {
        const { instalmentLength, instalmentFrequency } = getPayPlanVariantData(params, config);
        const { customerPayPlanEligibilityOutcome: customerEligibility, verificationToken: customerEligibilityToken } =
          await getPayPlanEligibilityCheck(config.apiBaseUri, params.publicKey, {
            customerId,
            planAmount: {
              amount: params.payplanAmount,
              currency: params?.currency,
            },
            initialPayment: amount || minFirstInstalmentAmount,
            instalmentLength,
            instalmentFrequency,
            customisedInstalmentSchedule: customisedInstalmentSchedule || [],
          });

        if (customerEligibility.offerPayPlan?.isCounterOffer) throw Error(GET_PAYPLAN_DATA_ERROR_MESSAGE);

        update({
          customerEligibility,
          customerEligibilityToken,
        });
        return { customerEligibility, customerEligibilityToken };
      } catch (e) {
        return {
          customerError: isFirstInstalmentChange ? GET_PAYPLAN_DATA_ERROR_MESSAGE : GET_PAYPLAN_DATA_DATE_ERROR_MESSAGE,
        };
      }
    },
    [params, config, customerId, minFirstInstalmentAmount, update],
  );

  return onInitialPaymentChange;
};

export const useCreatePayPlanData = () => {
  const { state, update } = useCheckoutContext();
  const { params, config, customerId, minFirstInstalmentAmount } = state;

  const onCreatePayPlanData = useCallback(
    async (
      customisedInstalmentSchedule?: CustomisedInstalmentSchedule,
    ): Promise<{ customerEligibility: CustomerEligibility; customerEligibilityToken: string }> => {
      if (!config) throw Error(STATE_ERROR_MESSAGE);

      const { instalmentLength, instalmentFrequency } = getPayPlanVariantData(params, config);
      const { customerPayPlanEligibilityOutcome: customerEligibility, verificationToken: customerEligibilityToken } =
        await getPayPlanEligibilityCheck(config.apiBaseUri, params.publicKey, {
          customerId,
          planAmount: {
            amount: params.payplanAmount,
            currency: params?.currency,
          },
          initialPayment: minFirstInstalmentAmount,
          instalmentLength,
          instalmentFrequency,
          customisedInstalmentSchedule: customisedInstalmentSchedule || [],
        });

      const payPlanData = customerEligibility.offerPayPlan?.payPlanData;

      update({
        customerEligibility,
        customerEligibilityToken,
        minFirstInstalmentAmount: payPlanData?.initialPayment || minFirstInstalmentAmount,
      });
      return { customerEligibility, customerEligibilityToken };
    },
    [params, config, customerId, minFirstInstalmentAmount, update],
  );

  return onCreatePayPlanData;
};

export const useCreatePayPlanSubmit = () => {
  const { state } = useCheckoutContext();
  const {
    params,
    config,
    cardPaymentSources,
    cardPaymentSourceId,
    veryGoodSecurityPaymentMethodToken,
    firebaseAuth,
    customerId,
    customerEligibility,
    customerEligibilityToken,
    veryGoodSecurityCardCvcToken,
    b2bCardUsageScope,
  } = state;

  const onCardinalDdcSubmit = useCardinalDdcSubmit();

  const onCreatePayPlanSubmit = useCallback(async () => {
    if (!config || !firebaseAuth || !customerEligibility || !customerEligibilityToken) throw Error(STATE_ERROR_MESSAGE);

    const customerIdToken = await firebaseAuth.currentUser?.getIdToken();
    if (!customerIdToken) throw Error(CUSTOMER_TOKEN_ERROR_MESSAGE);

    let _cardPaymentSourceId = cardPaymentSourceId;
    let cardBin = '';
    let cardCvc = '';

    // saved card?
    if (!_cardPaymentSourceId) {
      if (!veryGoodSecurityPaymentMethodToken) throw Error(STATE_ERROR_MESSAGE);

      if (config.isB2B && b2bCardUsageScope) {
        const { cardPaymentSourceId } = await addCustomerPaymentSourceScoped(
          config.apiBaseUri,
          customerId,
          customerIdToken,
          veryGoodSecurityPaymentMethodToken,
          b2bCardUsageScope,
        );
        _cardPaymentSourceId = cardPaymentSourceId;
      } else {
        const { cardPaymentSourceId } = await addCustomerPaymentSource(config.apiBaseUri, customerId, customerIdToken, {
          veryGoodSecurityPaymentMethodToken,
        });
        _cardPaymentSourceId = cardPaymentSourceId;
      }

      cardBin = veryGoodSecurityPaymentMethodToken.cardPaymentMethod.bin ?? '';
      cardCvc = veryGoodSecurityPaymentMethodToken.cardPaymentMethod.cardCvc;
    } else {
      const cardPaymentSource = cardPaymentSources.find((card) => card.cardPaymentSourceId === _cardPaymentSourceId);
      cardBin = cardPaymentSource?.cardBin ?? '';
      cardCvc = veryGoodSecurityCardCvcToken;
    }

    const threeDsSessionId = await onCardinalDdcSubmit(cardBin);

    const response = await createPayPlan(config.apiBaseUri, params.publicKey, customerIdToken, {
      customerId,
      cardPaymentSourceId: _cardPaymentSourceId,
      customerPayPlanEligibilityOutcome: customerEligibility,
      verificationToken: customerEligibilityToken,
      integrationCanHandle3DSOnPayOrder: semver.satisfies(params.version, '>=2.1.0'),
      cardCvc,
      threeDsSessionId,
    });

    return {
      payPlanId: response.payPlanId,
      paymentTokenId: response.paymentTokenId,
    };
  }, [
    config,
    firebaseAuth,
    customerEligibility,
    customerEligibilityToken,
    customerId,
    params,
    cardPaymentSources,
    cardPaymentSourceId,
    veryGoodSecurityPaymentMethodToken,
    veryGoodSecurityCardCvcToken,
    b2bCardUsageScope,
    onCardinalDdcSubmit,
  ]);

  return onCreatePayPlanSubmit;
};

export const usePrevious = <T>(value: T): T | undefined => {
  const prevRef = useRef<T>();
  const curRef = useRef(value);

  if (curRef.current !== value) {
    prevRef.current = curRef.current;
    curRef.current = value;
  }

  return prevRef.current;
};
