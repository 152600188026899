import styled from '@emotion/styled';

export const SavedCardLink = styled.a`
  color: var(--lp-colors-medium-blue-600) !important;
  text-decoration: underline;
`;

export const Links = styled.div`
  @media screen and (max-width: 508px) {
    text-align: right;
    padding-top: 20px;
  }
`;

export const SelectDelegateWrapper = styled.div`
  &.pay-plan {
    padding: 0 5px;
    margin-top: -15px;
  }
  &.pay-card {
    padding: 0 10px;
    box-shadow: none;
    .form-item {
      border-bottom: none;
    }
    .form-input {
      text-transform: none;
    }

    @media screen and (max-width: 508px) {
      padding: 0 8px;
      border: none;

      .form-item {
        border-bottom: 0;
        padding: 20px 0 16px;
      }
      .form-input {
        padding: 8px 22px;
      }
    }
  }
`;

export const SelectDelegateFormItem = styled.div<{ isPayPlan: boolean }>`
  border-bottom: ${({ isPayPlan }) => (isPayPlan ? '1px #e9f0f5 solid' : 'none')}
  padding: 12px 0 10px;
  margin-bottom: 5px;
  position: relative;
  width: 100%;
`;

export const CvcLabel = styled.label`
  margin-top: 15px;
`;

export const SavedCardFormItem = styled.div`
  border-bottom: 1px #e9f0f5 solid;
  padding: 12px 0 10px;
`;

export const CvcFormItem = styled.div`
  padding: 12px 0 10px;
`;
