import { Currency, PaymentType } from 'lib';

import { CardPaymentSource } from './types';

export interface Message {
  service: 'Limepay';
  elementId: string;
}

export enum EventName {
  Ready = 'limepay_checkout_ready_event',
  Init = 'limepay_checkout_init_event',
  Submit = 'limepay_checkout_submit_event',
  ToggledPaymentType = 'limepay_toggled_payment_type',
  FirstPaymentAmountChanged = 'limepay_first_payment_amount_changed',
  PaymentPlanTermsAccepted = 'limepay_payment_plan_terms_accepted',
}

export interface PaymentData {
  amount: number;
  currency: Currency;
  paymentType: PaymentType;
}

export interface EventMessage extends Message {
  event: {
    eventName: EventName;
    eventData?: any;
  };
}

export interface ErrorMessage extends Message {
  error: {
    message: string;
  };
}

export interface PaymentTokenMessage extends Message {
  paymentToken: string;
  paymentData: PaymentData;
  submit: boolean;
}

export interface CardPaymentSourceMessage extends Message {
  cardPaymentSource: CardPaymentSource;
}

export interface ResizeMessage extends Message {
  windowHeight: number;
}

export type AnyMessage = EventMessage | ErrorMessage | PaymentTokenMessage | CardPaymentSourceMessage | ResizeMessage;

export const toEventMessage = (eventName: EventName, elementId: string, eventData?: any): EventMessage => ({
  event: { eventName, eventData },
  service: 'Limepay',
  elementId,
});

export const toErrorMessage = (message: string, elementId: string): ErrorMessage => ({
  error: { message },
  service: 'Limepay',
  elementId,
});

export const toPaymentTokenMessage = (
  paymentToken: string,
  paymentData: PaymentData,
  elementId: string,
  submit = true,
): PaymentTokenMessage => ({
  paymentToken,
  paymentData,
  submit,
  service: 'Limepay',
  elementId,
});

export const toCardPaymentSourceMessage = (
  cardPaymentSource: CardPaymentSource,
  elementId: string,
): CardPaymentSourceMessage => ({
  cardPaymentSource,
  service: 'Limepay',
  elementId,
});

export const toResizeMessage = (windowHeight: number, elementId: string): ResizeMessage => ({
  windowHeight,
  service: 'Limepay',
  elementId,
});
