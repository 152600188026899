export type Env = 'dev' | 'tst' | 'stg' | 'prd';

let _headers: Headers | null = null;
export const getHeaders = async () => {
  if (_headers) return _headers;

  const { headers } = await fetch('/');
  _headers = headers;

  return _headers;
};

export const getEnv = async () => {
  const headers = await getHeaders();

  const env = (headers.get('env') ?? process.env.REACT_APP_ENV) as Env;

  return env;
};

export const getApiHost = async (): Promise<string> => {
  const headers = await getHeaders();

  let apiHost = headers.get('api-ingress-host') || '';

  if (!apiHost && process.env.NODE_ENV === 'development') {
    apiHost = process.env.REACT_APP_API_HOST || '';
  }

  if (!apiHost) throw Error('API host not found');

  return apiHost;
};

export const getApiHostEnv = (apiHost: string): Env => {
  if (apiHost.includes('.dev.')) return 'dev';
  if (apiHost.includes('.tst.')) return 'tst';
  if (apiHost.includes('.sandbox.')) return 'stg';
  return 'prd';
};

export const getHost = (env: Env) => {
  if (env === 'dev') return 'dev.au.meetapril.dev';
  if (env === 'tst') return 'tst.au.meetapril.dev';
  if (env === 'stg') return 'sandbox.limepay.com.au';
  return 'limepay.com.au';
};

export const getAprilHost = (env: Env) => {
  if (env === 'dev') return 'dev.au.meetapril.dev';
  if (env === 'tst') return 'tst.au.meetapril.dev';
  if (env === 'stg') return 'sandbox.au.meetapril.io';
  return 'au.meetapril.io';
};

export const getCardinalHost = async () => {
  const env = await getEnv();

  if (env === 'prd') return 'https://centinelapi.cardinalcommerce.com';
  return 'https://centinelapistag.cardinalcommerce.com';
};
