import { cx } from 'emotion';
import { PaymentType } from 'lib';
import React, { useEffect, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector/build/withPolyfill';

/** @jsx jsx */
import { css, jsx } from '@emotion/core';

const errorCss = css({
  fontSize: 15,
  fontWeight: 300,
  textAlign: 'center',
  color: '#3A3E46',
});

export interface LayoutProps {
  paymentType?: PaymentType;
  pending?: boolean;
  error?: string;
  checkoutCSSOverride?: string;
  onResize?(width?: number, height?: number): void;
}
export const Layout: React.FC<LayoutProps> = ({
  paymentType = 'paycard',
  pending = false,
  error,
  checkoutCSSOverride,
  onResize,
  children,
}) => {
  const [cssLoaded, setCssLoaded] = useState(!checkoutCSSOverride);
  const isPayPlan = paymentType === 'payplan';

  const { ref } = useResizeDetector({
    onResize,
    refreshMode: 'throttle',
    refreshRate: 150,
  });

  useEffect(() => {
    // timeout in case checkoutCSSOverride fails to load
    checkoutCSSOverride && setTimeout(() => setCssLoaded(true), 1000);
  }, [checkoutCSSOverride]);

  return (
    <>
      {!!checkoutCSSOverride && <link rel="stylesheet" href={checkoutCSSOverride} onLoad={() => setCssLoaded(true)} />}
      {cssLoaded && (
        <div ref={ref} id="lpCheckoutFrame" css={{ minHeight: 0 }}>
          <div className={cx('app-container', isPayPlan && 'three-p-active')}>
            {!!error && <p css={errorCss}>Error: {error}</p>}
            {!error && pending && <div className="loader" id="lpLoadingCont" role="alert" />}
            <div className="lp-payments" id="lpFormsCont">
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
