import React, { Dispatch, ReactNode, createContext, useReducer } from 'react';

import { AnyAction, State, reducer } from './';

export const StateContext = createContext<State | undefined>(undefined);
export const DispatchContext = createContext<Dispatch<AnyAction> | undefined>(undefined);

export interface CheckoutProviderProps {
  initalState: State;
  children?: ReactNode;
}
export const CheckoutProvider = ({ initalState, children }: CheckoutProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initalState);

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  );
};
