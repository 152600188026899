import firebase from 'firebase/app';
import { Env, UserClaims, getApiHost, getApiHostEnv, getHost } from 'lib';
import LogRocket from 'logrocket';

import { defaultInitOptions } from './logrocket.lib';

export { logRocketTrack } from './logrocket.lib';

const INIT_DELAY = 5000;

export const getSessionUrl = () => new Promise<string>((resolve) => LogRocket.getSessionURL(resolve));

const getAppId = (env: Env): string | null => {
  if (env === 'dev') return null;
  return `gx6dpd/limepay-${env}`;
};

let timeout: NodeJS.Timeout | null = null;
export const logRocketInit = (onInit?: (sessionUrl: string) => void) => {
  if (timeout) return;

  timeout = setTimeout(async () => {
    const env = getApiHostEnv(await getApiHost());
    const rootHostname = getHost(env);
    const appId = getAppId(env);

    if (appId) {
      LogRocket.init(appId, {
        ...defaultInitOptions,
        release: '2.1.0',
        rootHostname,
      });
      onInit?.(await getSessionUrl());
    }
  }, INIT_DELAY);
};

export const logRocketIdentify = async (firebaseAuth: firebase.auth.Auth) => {
  const currentUser = firebaseAuth.currentUser;
  if (!currentUser) return;

  const idTokenResult = await currentUser.getIdTokenResult();
  if (!idTokenResult) return;

  const claims = idTokenResult.claims as unknown as UserClaims;
  LogRocket.identify(claims.limepay.customerId, {
    email: claims.email,
  });
};
