/* eslint-disable jsx-a11y/anchor-is-valid */
import 'react-phone-number-input/style.css';

import { cx } from 'emotion';
import {
  CardPaymentSource,
  CardUsageScope,
  Config,
  CurrentUser,
  FORM_VALIDATION_ERROR_MESSAGE,
  Params,
  PaymentType,
  VgsCardError,
  VgsCardType,
  cardCvcId,
  cardCvcOnlyId,
  cardExpiryId,
  cardNumberId,
  checkPayPlanAmountEligibility,
  toCurrency,
  toCurrencySymbol,
  toVgsCardType,
  useCardinalDdcSubmit,
  validateEmail,
} from 'lib';
import React, { useMemo } from 'react';
import { Controller, FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';

import { VaultEvent } from '@april/lib-ui';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';

import {
  CvcFormItem,
  CvcLabel,
  Links,
  SavedCardFormItem,
  SavedCardLink,
  SelectDelegateFormItem,
  SelectDelegateWrapper,
} from './PaymentOption.styles';

const cardCss = css({
  height: 32,
  '& iframe': {
    width: '100%',
    height: '100%',
  },
});

const toCardTypeClass = (cardType?: VgsCardType) => {
  if (cardType === 'visa') {
    return 'visa';
  }
  if (cardType === 'mastercard') {
    return 'mCard';
  }
  if (cardType === 'amex') {
    return 'aexpress';
  }
};

const toBrandImgSrc = (brand: CardPaymentSource['brand']) => {
  const _brand = brand.toLowerCase();
  return ['visa', 'mastercard'].includes(_brand) ? `/v1.4/img/cards/img-fi-${_brand}.svg` : '';
};

const PhoneInputWrapper = styled.div`
  & .flag-container,
  .PhoneInputCountry {
    display: none;
  }
  & input {
    border: 0;
    font-size: 11px;
  }
`;

export interface PaymentOptionFormData {
  email?: string;
  phoneNo?: string;
  b2bSaveCard?: CardUsageScope;
}

export interface PaymentOptionProps {
  params: Params;
  config: Config;
  paymentType: PaymentType;
  email: string;
  phoneNo: string;
  emailVerificationRequired: boolean;
  phoneVerificationRequired: boolean;
  currentUser?: CurrentUser;
  cardPaymentSources: CardPaymentSource[];
  cardPaymentSourceId: string | null;
  onCardValid: () => void;
  setCardPaymentSourceId(cardPaymentSourceId: string | null): void;
  setEmail(value: string): void;
  setPhoneNo(value: string): void;
  setEmailVerificationRequired(value: boolean): void;
  setPhoneVerificationRequired(value: boolean): void;
  setEmailVerificationCode(value: string): void;
  setPhoneVerificationCode(value: string): void;
  setPending(value: boolean): void;
  cardError?: VgsCardError | null;
  customerError?: string;
  onMountVaultCard?(onEvent?: (event: VaultEvent) => void): Promise<void>;
  onPaymentTypeChanged(paymentType: PaymentType): void;
  onCardSubmit(): void;
  onPayCardSubmit(): void;
  onReviewPaymentPlanSubmit: SubmitHandler<PaymentOptionFormData>;
  onValidationError?(error: string): void;
  setB2BCardUsageScope?(scope: CardUsageScope): void;
}
export const PaymentOption: React.FC<PaymentOptionProps> = ({
  params,
  config,
  paymentType,
  email,
  phoneNo,
  emailVerificationRequired = true,
  setEmail,
  setPhoneNo,
  setEmailVerificationRequired,
  phoneVerificationRequired = true,
  setPhoneVerificationRequired,
  currentUser,
  cardPaymentSources,
  cardPaymentSourceId,
  setCardPaymentSourceId,
  cardError,
  customerError,
  setPending,
  onMountVaultCard,
  onPaymentTypeChanged,
  onCardSubmit,
  onPayCardSubmit,
  onReviewPaymentPlanSubmit,
  onValidationError,
  onCardValid,
  setB2BCardUsageScope,
}) => {
  const [cardType, setCardType] = React.useState<VgsCardType>();
  const [cardNumberFocus] = React.useState(false);
  const [cardNumberError, setCardNumberError] = React.useState<string>(cardError?.cardNumberError || '');
  const [expiryDateFocus] = React.useState(false);
  const [expiryDateError, setExpiryDateError] = React.useState<string>(cardError?.expiryDateError || '');
  const [cardCvcFocus] = React.useState(false);
  const [cardCvcError, setCardCvcError] = React.useState<string>(cardError?.cardCvcError || '');
  const [requestForPhoneNo, setRequestForPhoneNo] = React.useState(true);
  const [requestForEmail, setRequestForEmail] = React.useState(true);
  // const [limepayAgreementRequired, setLimepayAgreementRequired] = React.useState(true);
  // const [payWithNewCard, setPayWithNewCard] = React.useState(true);
  // const [savedCardsList, setSavedCardsList] = React.useState([]);
  const isPayCard = params.showCardOnly || paymentType === 'paycard';
  const isPayPlan = !params.showCardOnly && paymentType === 'payplan';
  const isDeferred = config.payPlanVariant === 'payplan_fortnight_0_100';
  const cardFormError = cardError?.formError || '';

  const providedEmail = params.email;
  const providedPhoneNo = params.phoneNo;
  const restrictToEmail = !!params.emailAndSmsCheckoutTokenVerified;

  const {
    minPayPlanAmount,
    maxPayPlanAmount,
    minPayPlanAmountEligibility,
    maxPayPlanAmountEligibility,
    payPlanAmountEligibility,
  } = useMemo(() => checkPayPlanAmountEligibility(params, config), [params, config]);

  //only allow for hideFullPayOption if config.allowHideFullPay is True
  const hidePaymentOptions = !payPlanAmountEligibility && config.allowHideFullPay && params.hideFullPayOption;

  const allowSavedCards = !!cardPaymentSources.length;
  const showSavedCards = allowSavedCards && cardPaymentSourceId !== null;
  const hidePayPlan = params.showCardOnly || params.hidePayLaterOption || (config.isB2B && !currentUser);

  const formMethods = useForm<PaymentOptionFormData>({
    defaultValues: {
      email,
      phoneNo,
    },
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = formMethods;

  React.useEffect(() => {
    setCardNumberError(cardError?.cardNumberError || '');
    setExpiryDateError(cardError?.expiryDateError || '');
    setCardCvcError(cardError?.cardCvcError || '');
  }, [cardError]);

  const onCardinalDdcSubmit = useCardinalDdcSubmit();

  const loadVaultCard = React.useCallback(async () => {
    await onMountVaultCard?.(({ eventName, field, vault }) => {
      if (eventName === 'Change') {
        const { isValid } = vault.validate();

        if (field.type === 'cardNumber') {
          const cardType = vault.fields.cardNumber?.state.cardType;
          const cardBin = vault.fields.cardNumber?.state.bin;
          setCardType(cardType ? toVgsCardType(cardType) : undefined);
          setCardNumberError('');
          cardBin && onCardinalDdcSubmit(cardBin);
        }
        if (field.type === 'expiryDate') {
          setExpiryDateError('');
        }
        if (field.type === 'cardCvc') {
          setCardCvcError('');
        }

        if (isValid) {
          onCardValid();
        }
      }
    });
  }, [onMountVaultCard, onCardValid, onCardinalDdcSubmit]);

  React.useEffect(() => {
    loadVaultCard();
  }, [loadVaultCard]);

  React.useEffect(() => {
    if (!cardPaymentSourceId) return;

    const cardPaymentSource = cardPaymentSources.find((card) => card.cardPaymentSourceId === cardPaymentSourceId);
    cardPaymentSource?.cardBin && onCardinalDdcSubmit(cardPaymentSource.cardBin);
  }, [cardPaymentSources, cardPaymentSourceId, onCardinalDdcSubmit]);

  React.useEffect(() => {
    setRequestForPhoneNo(true);
    setRequestForEmail(true);
    setEmailVerificationRequired(true);
    setPhoneVerificationRequired(true);
    // setLimepayAgreementRequired(true);

    // if (!payWithNewCard && savedCardsList.length === 0) {
    //   setPayWithNewCard(true);
    // }

    if (providedEmail) {
      if (params.emailAndSmsCheckoutTokenVerified) {
        setRequestForPhoneNo(false);
        setRequestForEmail(false);
        setEmailVerificationRequired(false);
        setPhoneVerificationRequired(false);
        return;
      }

      if (restrictToEmail) {
        setRequestForEmail(false);
      }

      // We're not consuming GetCustomer API, do not check for isCustomerVerified
      // if (isCustomerVerified) { setRequestForPhoneNo(false); }
    }
  }, [params, providedEmail, restrictToEmail, setEmailVerificationRequired, setPhoneVerificationRequired]);

  const togglePaymentType = () => {
    payPlanAmountEligibility && onPaymentTypeChanged(isPayCard ? 'payplan' : 'paycard');
  };

  const handleValidationError: SubmitErrorHandler<PaymentOptionFormData> = React.useCallback(
    (errors) => onValidationError?.(FORM_VALIDATION_ERROR_MESSAGE),
    [onValidationError],
  );

  const handleChangeSaveCard = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setB2BCardUsageScope?.(e.target.value as CardUsageScope);
  };

  return (
    <div id="lpPaymentOptionCont">
      {!((config.allowHideFullPay && params.hideFullPayOption) || hidePayPlan) && (
        <div className="payment-options poReset lpPayLaterOptionCont">
          <div className="pOption" onClick={togglePaymentType}>
            <div className="p-ontime">
              <div className="lbl">{isDeferred ? 'PAY NOW' : 'ONE TIME PAYMENT'}</div>
              <div className="price">
                <span>{toCurrencySymbol(params.currency)}</span>
                {toCurrency(params.paycardAmount)}
              </div>
              {!!params.paycardSurchargeAmount && (
                <div className="surcharge lbl">
                  (inc. <span>{toCurrencySymbol(params.currency)}</span>
                  {toCurrency(params.paycardSurchargeAmount)} surcharge)
                </div>
              )}
            </div>
          </div>
          <div className="pl-4 pr-4">
            <label className="switch pull-right lpPaymentTypeOption">
              <input
                className="lpPaymentOptionCheckbox"
                type="checkbox"
                checked={isPayPlan}
                disabled={!payPlanAmountEligibility}
                onChange={togglePaymentType}
              />
              <span className="slider round" />
            </label>
          </div>
          <div className="pOption" onClick={togglePaymentType}>
            {isDeferred ? (
              <div className="p-three lpEqlPayAmtLabel">
                <div className="lbl">PAY LATER</div>
                <div className="price lpBalInstCurrSpanPH">
                  <span>{toCurrencySymbol(params.currency)}</span>
                  {toCurrency(params.payplanAmount)}
                </div>
                {!!params.payplanSurchargeAmount && (
                  <div className="surcharge lbl">
                    (inc. <span>{toCurrencySymbol(params.currency)}</span>
                    {toCurrency(params.payplanSurchargeAmount)} surcharge in total)
                  </div>
                )}
              </div>
            ) : (
              <div className="p-three lpEqlPayAmtLabel">
                <div className="lbl">
                  4 <span style={{ fontWeight: 'bolder', whiteSpace: 'nowrap' }}>INTEREST FREE</span> PAYMENTS FROM
                </div>
                <div className="price lpBalInstCurrSpanPH">
                  <span>{toCurrencySymbol(params.currency)}</span>
                  {toCurrency(params.payplanAmount / 4)}
                </div>
                {!!params.payplanSurchargeAmount && (
                  <div className="surcharge lbl">
                    (inc. <span>{toCurrencySymbol(params.currency)}</span>
                    {toCurrency(params.payplanSurchargeAmount)} surcharge in total)
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {!hidePayPlan && !payPlanAmountEligibility && (
        <div className="lpPayPlanRestrictedMessage">
          {!minPayPlanAmountEligibility && (
            <div className="lpBelowMinPlanAmount">
              <p className="small-text pt-3 pt-sm-1 pb-3 pb-sm-0 color-grey">
                Split the cost over 4 payments when you spend{' '}
                <span className="lpMinAllowedPlanAmount">
                  <span>{toCurrencySymbol(params.currency)}</span>
                  {toCurrency(minPayPlanAmount)}
                </span>{' '}
                or more.
              </p>
            </div>
          )}
          {!maxPayPlanAmountEligibility && (
            <div className="lpMaxedAllowedPlanLimit">
              <p className="small-text pt-3 pt-sm-1 pb-3 pb-sm-0 color-grey">
                Split the cost over 4 payments on purchases of up to{' '}
                <span className="lpMaxPlanAmount">
                  <span>{toCurrencySymbol(params.currency)}</span>
                  {toCurrency(maxPayPlanAmount)}
                </span>
                .
              </p>
            </div>
          )}
        </div>
      )}
      {!hidePaymentOptions && (
        <div id="lpCCardPaymentOptionCont" className={cx(isPayCard && 'ontime-p', isPayPlan && 'three-p')}>
          <div id="lpCardCont">
            <div className="example example2" id="lpNewCardCont" style={{ display: showSavedCards ? 'none' : 'block' }}>
              <div className={cx('lpNewCardInnerCont', isPayCard && 'fcard text-left', isPayPlan && 'form-inputs')}>
                <div className="row">
                  <div className={cx('lpColCardNumber', isPayCard && 'col-sm-6', isPayPlan && 'col-sm-6 pr-3 pr-sm-1')}>
                    <div className={cx('form-item lpCardNumberErrorCont', cardNumberError && 'error')}>
                      <div className="ccard form-input">
                        <div
                          css={cardCss}
                          className={cx('stripe-input', cardNumberFocus && 'focus', toCardTypeClass(cardType))}
                          id={cardNumberId}
                        ></div>
                        <div className="lpCardNumberError error-msg eontime">{cardNumberError}</div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={cx(
                      'lpColExpDate',
                      isPayCard && 'col-6 col-sm-3 pl-3 pl-sm-1 pr-0 pr-sm-3',
                      isPayPlan && 'col-sm-3 pl-3 pr-3 pl-sm-1 pr-sm-1',
                    )}
                  >
                    <div className={cx('form-item lpCardExpErrorCont', expiryDateError && 'error')}>
                      <div className="ex-date form-input">
                        <div
                          css={cardCss}
                          className={cx('stripe-input', expiryDateFocus && 'focus')}
                          id={cardExpiryId}
                        ></div>
                        <div className="lpCardExpError error-msg eontime">{expiryDateError}</div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={cx(
                      'lpColCVV',
                      isPayCard && 'col-6 col-sm-3 pl-0 pl-sm-1',
                      isPayPlan && 'col-sm-3 pl-3 pl-sm-1 pb-2 pb-sm-0',
                    )}
                  >
                    <div className={cx('form-item lpCardCvcErrorCont', cardCvcError && 'error')}>
                      <div className="cvv form-input">
                        <div css={cardCss} className={cx('stripe-input', cardCvcFocus && 'focus')} id={cardCvcId}></div>
                        <div className="lpCardCvcError error-msg eontime">{cardCvcError}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="error-msg-noa" id="lpCardError" style={{ marginTop: 15, wordBreak: 'break-word' }}>
                  {cardFormError}
                </div>
              </div>
              {!showSavedCards && config.isB2B && currentUser && (
                <SelectDelegateWrapper className={`${isPayPlan ? 'pay-plan' : 'pay-card three-p'}`}>
                  <div className="row">
                    <div className="col-sm-12">
                      <SelectDelegateFormItem
                        isPayPlan={isPayPlan}
                        className={cx('lpIdMethodErrorMsgCont form-item', errors.b2bSaveCard && 'error')}
                      >
                        <select
                          ref={register({ required: isPayPlan && 'Please select an option' })}
                          className="date form-input"
                          id="lpB2bSaveCard"
                          name="b2bSaveCard"
                          autoComplete="off"
                          onChange={handleChangeSaveCard}
                        >
                          <option value="">Save card for...</option>
                          <option value={CardUsageScope.delegate}>My user profile</option>
                          <option value={CardUsageScope.organisation}>All users</option>
                        </select>
                        <p className="error-msg lpIdMethodErrorMsg">{isPayPlan && errors.b2bSaveCard?.message}</p>
                      </SelectDelegateFormItem>
                    </div>
                  </div>
                </SelectDelegateWrapper>
              )}
              {allowSavedCards && (
                <Links
                  className="links"
                  style={{ marginLeft: `${isPayCard ? '0' : '10px'}`, marginTop: `${isPayCard ? '10px' : '5px'}` }}
                >
                  <SavedCardLink
                    className="lpUseSavedCardButton link style2"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setCardPaymentSourceId(cardPaymentSources[0].cardPaymentSourceId);
                    }}
                  >
                    Use Saved Card
                  </SavedCardLink>
                </Links>
              )}
            </div>
            <div id="lpSavedCardsListCont" style={{ display: showSavedCards ? 'block' : 'none' }}>
              <div className="form-inputs">
                <div className="preloaded-cards">
                  <div className="cards-list">
                    {cardPaymentSources.map((card) => {
                      return (
                        <SavedCardFormItem key={card.cardPaymentSourceId} className="form-item row ml-0 mr-0">
                          <div className="col-10 pl-0">
                            <label className="change-card lp-radio">
                              <span className="cno">
                                <span className="hidden-no">•••• •••• ••••</span> {card.last4}
                              </span>
                              <input
                                type="radio"
                                name="cardPaymentSource"
                                value={card.cardPaymentSourceId}
                                checked={cardPaymentSourceId === card.cardPaymentSourceId}
                                onChange={() => setCardPaymentSourceId(card.cardPaymentSourceId)}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                          <div className="col-2 v-flex-center">
                            <div className="cards">
                              <img src={toBrandImgSrc(card.brand)} alt="" />
                            </div>
                          </div>
                        </SavedCardFormItem>
                      );
                    })}
                  </div>
                  <CvcLabel data-testid="cardCvcLabel">Please enter your card's 3 or 4-digit security code:</CvcLabel>
                  <div className="row">
                    <div className="lpColCVV col-6 col-sm-3 pl-3">
                      <CvcFormItem className={cx('form-item lpCardCvcErrorCont', cardCvcError && 'error')}>
                        <div className="cvv form-input">
                          <div
                            css={cardCss}
                            className={cx('stripe-input', cardCvcFocus && 'focus')}
                            id={cardCvcOnlyId}
                          ></div>
                          <div className="lpCardCvcError error-msg eontime">{cardCvcError}</div>
                        </div>
                      </CvcFormItem>
                    </div>
                  </div>
                  <Links className="links" style={{ marginTop: `${isPayCard ? '0' : '15px'}` }}>
                    <SavedCardLink
                      className="link style2"
                      id="lpUseADifferentCard"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setCardPaymentSourceId(null);
                      }}
                    >
                      Manually Enter Card
                    </SavedCardLink>
                  </Links>
                </div>
              </div>
              <div className="error-msg-noa" id="lpCardError" style={{ marginTop: 15, wordBreak: 'break-word' }}>
                {cardFormError}
              </div>
            </div>
          </div>
          {!params.showCardOnly && isPayCard && (
            <div className="row">
              <div className="col-sm-12 details" style={{ paddingTop: 0, paddingBottom: 0 }}>
                <p className="lpPayCardTerms">
                  This payment will be processed by April Solutions. To learn more, see Terms of Use{' '}
                  <a
                    className="link style6 d-inline-block d-sm-inline"
                    href={config.tcUrl}
                    rel="noreferrer"
                    target="_blank"
                  >
                    here
                  </a>
                  .
                </p>
              </div>
            </div>
          )}
          {isPayPlan && (
            <FormProvider {...formMethods}>
              <div id="lpPaymentOptionsToggleCont">
                <div id="lpCustomerInfoCont">
                  <div className="form-inputs">
                    {!currentUser?.isVerified && (
                      <div className="row">
                        <div className="col-sm-6">
                          {requestForPhoneNo ? (
                            <div className={cx('form-item lpPhoneNoInputCont', errors.phoneNo && 'error')}>
                              <PhoneInputWrapper>
                                <Controller
                                  name="phoneNo"
                                  rules={{
                                    validate: (value) =>
                                      isPossiblePhoneNumber(value || '') ? true : 'Invalid phone number',
                                  }}
                                  control={control}
                                  defaultValue={phoneNo || ''}
                                  render={(props) => (
                                    <PhoneInput
                                      className="phone"
                                      defaultCountry={config.merchantTradingCountry}
                                      placeholder="PHONE NUMBER"
                                      {...props}
                                    />
                                  )}
                                />
                              </PhoneInputWrapper>
                              <div className="error-msg" id="lpPhoneNoErrors">
                                {errors.phoneNo?.message}
                              </div>
                            </div>
                          ) : (
                            <div className="form-item">
                              <input
                                className="card-n"
                                id="lpVerifiedPhoneNumber"
                                type="text"
                                name="verifiedPhoneNo"
                                value={providedPhoneNo}
                                readOnly
                                disabled
                                style={{
                                  border: 'none',
                                  width: '100%',
                                  background: 'none',
                                  marginTop: 6,
                                  marginBottom: 8,
                                }}
                              />
                            </div>
                          )}
                        </div>
                        <div className="col-sm-6 pl-3 pl-sm-1">
                          {requestForEmail ? (
                            <div className={cx('form-item lpEmailInputCont', errors.email && 'error')}>
                              <input
                                ref={register({ validate: (value) => (validateEmail(value) ? true : 'Invalid Email') })}
                                name="email"
                                className="email emailAdd form-input"
                                id="lpCustEmail"
                                type="email"
                                placeholder="EMAIL ADDRESS"
                                defaultValue={email || ''}
                                readOnly={Boolean(currentUser)}
                              />
                              <div className="error-msg" id="lpEmailErrors">
                                {errors.email?.message}
                              </div>
                            </div>
                          ) : (
                            <div className="form-item">
                              <input
                                className="card-n"
                                id="lpVerifiedEmail"
                                type="text"
                                name="verifiedEmail"
                                value={email}
                                readOnly
                                disabled
                                style={{
                                  border: 'none',
                                  width: '100%',
                                  background: 'none',
                                  marginTop: 6,
                                  marginBottom: 8,
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-sm-12 details">
                        <p className="lpPlanTerms">
                          By continuing, you consent to your personal information being disclosed and used to help check
                          and verify your identity. April collect and use the information you provide to administer your
                          payment plan on behalf of the merchant. It is a condition of the payment plan that you provide
                          this information.
                          <br />
                          <br />
                          To ensure this product is suitable for you, April may perform credit checks on this purchase.
                          You can check the information we hold about you at any time. Learn more by reading our{' '}
                          <a
                            className="link style6 d-inline-block d-sm-inline"
                            href={config.privacyUrl}
                            rel="noreferrer"
                            target="_blank"
                          >
                            Privacy Policy
                          </a>{' '}
                          and{' '}
                          <a
                            className="link style6 d-inline-block d-sm-inline"
                            href={config.tcUrl}
                            rel="noreferrer"
                            target="_blank"
                          >
                            Terms of Use
                          </a>
                          .
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 lpRestrictionMessage error-msg-noa">{customerError}</div>
                    </div>
                  </div>
                </div>
              </div>
            </FormProvider>
          )}
          {params.showCardOnly && params.showCardSubmit && (
            <div id="lpFullPaymentCont">
              <div className="form-action">
                <input
                  css={{ border: 0, cursor: 'pointer' }}
                  className="cta"
                  id="lpPayCardSubmitButton"
                  type="button"
                  value="Submit card"
                  onClick={handleSubmit(onCardSubmit, handleValidationError)}
                />
              </div>
            </div>
          )}
          {!params.showCardOnly && isPayCard && params.showPayNow && (
            <div id="lpFullPaymentCont">
              <div className="form-action">
                <input
                  css={{ border: 0, cursor: 'pointer' }}
                  className="cta"
                  id="lpPayCardSubmitButton"
                  type="button"
                  value="Pay now"
                  onClick={handleSubmit(onPayCardSubmit, handleValidationError)}
                />
              </div>
            </div>
          )}
          {isPayPlan && (
            <div id="lpPayPlanSubmitBtnCont">
              <div className="form-action">
                <a
                  className="cta"
                  id="lpPayPlanSubmitButton"
                  href="#"
                  onClick={handleSubmit(onReviewPaymentPlanSubmit, handleValidationError)}
                >
                  Review payment plan
                </a>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
