import dayjs from 'dayjs';
import { VgsCardError, handleVaultSubmitError, toCardError, toVgsCardType } from 'lib';

import { Vault, VaultCardType, handleVaultSubmitResponse } from '@april/lib-ui';

import { CardPaymentMethod, VeryGoodSecurityPaymentMethodToken, defaultHeaders } from './api';

export type VaultTokenizedCard = Omit<CardPaymentMethod, 'cardType'> & {
  cardType: VaultCardType;
};

export const submitVaultCard = async (
  vault: Vault,
  publicKey: string,
): Promise<{
  response?: VeryGoodSecurityPaymentMethodToken;
  cardError?: VgsCardError;
}> => {
  const { isValid, errors } = vault.validate();

  if (!isValid) return { cardError: toCardError(errors) };

  const { last4, cardType, bin } = vault.fields.cardNumber?.state ?? {};
  const { TokenizedCard } = await vault
    .submit({
      path: '/vault/card',
      method: 'POST',
      body: JSON.stringify({
        Card: {
          cardNumber: '{{cardNumber}}',
          expiryDate: {
            expiryMonth: '{{expiryMonth}}',
            expiryYear: '{{expiryYear}}',
          },
          cardCvc: '{{cardCvc}}',
          last4,
          cardType,
          bin,
        },
      }),
      headers: {
        ...defaultHeaders,
        'Limepay-PublicKey': publicKey,
      },
    })
    .then(handleVaultSubmitError)
    .then<{ TokenizedCard: VaultTokenizedCard }>(handleVaultSubmitResponse);

  const response = {
    cardPaymentMethod: {
      ...TokenizedCard,
      cardType: toVgsCardType(TokenizedCard.cardType),
    },
    createdAt: dayjs().format(),
  };

  return { response };
};

export const submitVaultCvc = async (
  vault: Vault,
  publicKey: string,
): Promise<{
  response?: { cardCvc: string };
  cardError?: VgsCardError;
}> => {
  const { isValid, errors } = vault.validate();

  if (!isValid) return { cardError: toCardError(errors) };

  const { TokenizedCvc } = await vault
    .submit({
      path: '/vault/card',
      method: 'POST',
      body: JSON.stringify({
        Cvc: {
          cardCvc: '{{cardCvc}}',
        },
      }),
      headers: {
        ...defaultHeaders,
        'Limepay-PublicKey': publicKey,
      },
    })
    .then(handleVaultSubmitError)
    .then<{ TokenizedCvc: { cardCvc: string } }>(handleVaultSubmitResponse);

  const response = {
    cardCvc: TokenizedCvc.cardCvc,
  };

  return { response };
};
