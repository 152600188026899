import 'react-datepicker/dist/react-datepicker.css';

import { addDays, subDays } from 'date-fns';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
  Config,
  CustomisedInstalmentSchedule,
  EventName,
  Params,
  PayPlanData,
  checkIsValidateDate,
  currencyFormat,
  instalmentNames,
  isNumeric,
  toCurrency,
  toCurrencySymbol,
  toEventMessage,
  useInitialPaymentChange,
  usePrevious,
  useSendMessage,
  validateFirstInstalmentAmount,
} from 'lib';
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import DatePicker from 'react-datepicker';

import styled from '@emotion/styled';

dayjs.extend(customParseFormat);

const DatePickerWrapper = styled.div`
  margin-bottom: 2px;
  .react-datepicker-popper {
    margin-top: 30px;
  }
`;

export interface PlanReviewProps {
  params: Params;
  config: Config;
  isCounterOffer: boolean;
  payPlanData: PayPlanData | null;
  payPlanError: string;
  firstInstalmentAmount: number;
  minFirstInstalmentAmount: number;
  isFirstPaymentEdit: boolean;
  isTermsAgreed: boolean;
  setIsTermsAgreed(agreed: boolean): void;
  setIsFirstPaymentEdit(fn: (value: boolean) => boolean): void;
  onPayPlanSubmit(): void;
  onBack(): void;
}

export const PlanReview: React.FC<PlanReviewProps> = ({
  params,
  config,
  isCounterOffer,
  payPlanData,
  payPlanError,
  firstInstalmentAmount,
  minFirstInstalmentAmount,
  isFirstPaymentEdit,
  isTermsAgreed,
  setIsTermsAgreed,
  setIsFirstPaymentEdit,
  onPayPlanSubmit,
  onBack,
}) => {
  const sendMessage = useSendMessage();
  const onInitialPaymentChange = useInitialPaymentChange();

  const [isEditSchedule, setIsEditSchedule] = React.useState(false);
  const [editScheduleIndex, setEditScheduleIndex] = React.useState(-1);
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);
  const [changeScheduleError, setChangeScheduleError] = React.useState<string>('');
  const [initialPaymentAmount, setInitialPaymentAmount] = React.useState<string>(
    currencyFormat(firstInstalmentAmount, params.currency),
  );
  const [customisedInstalmentSchedule, setCustomisedInstalmentSchedule] = React.useState<CustomisedInstalmentSchedule>(
    [],
  );
  const [proposedScheduleAt, setProposedScheduleAt] = React.useState<string[]>([]);

  const itemsRef = React.useRef<Array<DatePicker | null>>([]);
  itemsRef.current = itemsRef.current.slice(0, 4);

  const prevEditScheduleIndex: number | undefined = usePrevious(editScheduleIndex);
  const prevProposedScheduleAt: string[] | undefined = usePrevious(proposedScheduleAt);

  const PROPOSED_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS';
  const TARGET_DATE_FORMAT = 'DD/MM/YYYY HH:mm:ss.SSS';

  const { balanceInstalmentsCount, balanceInstalmentAmount, scheduleStartDate } = React.useMemo(() => {
    const proposedSchedule = payPlanData?.proposedSchedule;

    return {
      balanceInstalmentsCount: proposedSchedule ? proposedSchedule.length - 1 : 0,
      balanceInstalmentAmount: currencyFormat(proposedSchedule?.[1].amount || 0, params.currency),
      scheduleStartDate: dayjs(proposedSchedule?.[1].at).format('D MMM YYYY'),
    };
  }, [params, payPlanData]);

  const currencySymbol = toCurrencySymbol(params.currency);
  const isPayInFull = isCounterOffer && minFirstInstalmentAmount === params.payplanAmount;
  const isDeferred = config.payPlanVariant === 'payplan_fortnight_0_100';
  const firstInstalmentPercent = React.useMemo(
    () => `${((minFirstInstalmentAmount / params.payplanAmount) * 100).toFixed(0)}%`,
    [params, minFirstInstalmentAmount],
  );
  const planTitle = isCounterOffer
    ? isPayInFull
      ? 'Pay in Full to Proceed'
      : 'Confirm Update to Your Payment Plan'
    : 'Your Payment Plan';

  const handleGoBack = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    onBack();
  };

  const handleScheduleChange = (index: number) => {
    setEditScheduleIndex(index);
    setSelectedDate(null);
  };

  const handleFirstInstalmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e?.preventDefault();
    const value = e.target.value;
    const firstInstalmentAmount = parseFloat(value) * 100;

    if (isNumeric(value) || value === '') {
      setInitialPaymentAmount(value);
    }

    const validationResult = validateFirstInstalmentAmount({
      firstInstalmentAmount,
      minFirstInstalmentAmount,
      amount: params.payplanAmount,
    });
    setChangeScheduleError(validationResult);
  };

  const handleBlur = ({ target: { value } }: React.FocusEvent<HTMLInputElement>, base: string) => {
    const isValidDate = checkIsValidateDate(value, 'DD/MM/YYYY');
    const baseDateAndTime = dayjs(base).toISOString().split('T');
    const time = baseDateAndTime[1];
    const targetDateAndTime = `${value} ${time}`;

    if (isValidDate) {
      const diffInDay = dayjs(targetDateAndTime, TARGET_DATE_FORMAT).diff(dayjs(base, PROPOSED_DATE_FORMAT), 'day');
      if (Math.abs(diffInDay) > 2) {
        handleScheduleCancel();
      }
    } else {
      handleScheduleCancel();
    }
  };

  const handleScheduleCancel = () => {
    setEditScheduleIndex(-1);
    setSelectedDate(null);
  };

  const toggleFirstInstalmentEdit = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e?.preventDefault();
    setIsFirstPaymentEdit((prevState: boolean) => !prevState);
  };

  const toggleEditSchedule = (e?: React.MouseEvent<HTMLAnchorElement>) => {
    e?.preventDefault();
    setIsEditSchedule((prevState) => !prevState);
  };

  const handlePrevious = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e?.preventDefault();
    toggleEditSchedule();
  };

  const handleDateSelect = (e: Date | null) => {
    setSelectedDate(e);
  };

  React.useEffect(() => {
    switch (editScheduleIndex) {
      case -1:
      case 0:
        break;
      default:
        setChangeScheduleError('');
        itemsRef?.current[editScheduleIndex]?.setOpen(true);
    }
  }, [editScheduleIndex, prevEditScheduleIndex]);

  React.useEffect(() => {
    setInitialPaymentAmount(currencyFormat(firstInstalmentAmount, params.currency));
  }, [params, firstInstalmentAmount]);

  React.useEffect(() => {
    if ((prevProposedScheduleAt && prevProposedScheduleAt.length > 0) || !payPlanData?.proposedSchedule) {
      return;
    } else {
      const proposedScheduleAt = payPlanData?.proposedSchedule.map(({ at }) => at);
      setProposedScheduleAt(proposedScheduleAt);
    }
  }, [payPlanData, prevProposedScheduleAt]);

  const handleDateChange = React.useCallback(
    (e: Date | [Date, Date] | null) => {
      if (!payPlanData || !payPlanData?.proposedSchedule || !e || Array.isArray(e)) return false;

      const at = dayjs(e).toISOString();
      const newSchedule = { instalment: editScheduleIndex + 1, at }; // 1 based
      let newSchedules: CustomisedInstalmentSchedule = [];

      if (customisedInstalmentSchedule.length === 0) {
        newSchedules = [...customisedInstalmentSchedule, newSchedule];
      } else {
        const matchedSchedule = customisedInstalmentSchedule.filter(
          ({ instalment }) => instalment === editScheduleIndex + 1,
        );
        if (matchedSchedule.length) {
          newSchedules = customisedInstalmentSchedule.map((schedule) => {
            return schedule.instalment === editScheduleIndex + 1 ? newSchedule : schedule;
          });
        } else {
          newSchedules = [...customisedInstalmentSchedule, newSchedule];
        }
      }
      setCustomisedInstalmentSchedule(newSchedules);
    },
    [customisedInstalmentSchedule, editScheduleIndex, payPlanData, setCustomisedInstalmentSchedule],
  );

  const handleConfirm = React.useCallback(
    async (e: React.MouseEvent<HTMLAnchorElement | HTMLDivElement>) => {
      e.preventDefault();

      if (!firstInstalmentAmount) return false;

      const requestPayload = { amount: parseFloat(initialPaymentAmount) * 100, customisedInstalmentSchedule };
      const { customerError, customerEligibility, customerEligibilityToken } = await onInitialPaymentChange(
        requestPayload,
      );

      if (customerError) {
        setChangeScheduleError(customerError);
        return false;
      }

      if (!customerEligibility || !customerEligibilityToken) {
        return false;
      }

      handleScheduleCancel();
      setChangeScheduleError('');
    },
    [customisedInstalmentSchedule, firstInstalmentAmount, initialPaymentAmount, onInitialPaymentChange],
  );

  const handleFirstInstalmentChangeConfirm = React.useCallback(
    async (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();

      if (changeScheduleError) return false;

      const amount = parseFloat(initialPaymentAmount) * 100;
      const requestPayload = { amount, customisedInstalmentSchedule, isFirstInstalmentChange: true };
      const { customerError } = await onInitialPaymentChange(requestPayload);

      if (customerError) {
        setChangeScheduleError(customerError);
        return false;
      }

      sendMessage(
        toEventMessage(EventName.FirstPaymentAmountChanged, params.elementId, {
          previousFirstPaymentAmount: firstInstalmentAmount,
          updatedFirstPaymentAmount: amount,
          currency: params.currency,
        }),
      );

      handleScheduleCancel();
      setChangeScheduleError('');
    },
    [
      params,
      firstInstalmentAmount,
      onInitialPaymentChange,
      changeScheduleError,
      initialPaymentAmount,
      customisedInstalmentSchedule,
      sendMessage,
    ],
  );

  const handleTermsAgreeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.checked && setIsTermsAgreed(true);
  };

  const reviewPlanSummary = (
    <>
      <div className="row pt-4 pt-sm-3 pb-3">
        <div className="col-sm-4 pr-0 pb-2 pb-sm-0">
          <h3 className="title-h3">
            Due now:{' '}
            <span className="lpFirstInstalmentAmountPH">
              <span>{currencySymbol}</span>
              {initialPaymentAmount}
            </span>
          </h3>
          {!!params.payplanSurchargeAmount && (
            <div className="surcharge lbl active">
              (inc. <span>{toCurrencySymbol(params.currency)}</span>
              {toCurrency(params.payplanSurchargeAmount)} surcharge in total)
            </div>
          )}
        </div>
        {!isPayInFull && !isDeferred && (
          <div className="col-sm-8 pl-3 pl-sm-1">
            <a className="link style1 lpChangeFirstInstalmentAmountButton" href="#" onClick={toggleFirstInstalmentEdit}>
              Pay more now
            </a>
          </div>
        )}
      </div>
      {!isPayInFull && (
        <div className="row pb-2">
          <div className="col-sm-8 pr-3 pr-sm-0 pb-2 pb-sm-0 pt-2 pt-sm-0">
            {isDeferred ? (
              <p>
                Payment of{' '}
                <span className="lpBalanceInstalmentAmountPH">
                  <span>{currencySymbol}</span>
                  {balanceInstalmentAmount}
                </span>{' '}
                due on <span className="lpFirstInstalmentDatePH">{scheduleStartDate}</span>
              </p>
            ) : (
              <p>
                <span className="lpBalanceInstalmentsCountPH">{balanceInstalmentsCount}</span> fortnightly payments of{' '}
                <span className="lpBalanceInstalmentAmountPH">
                  <span>{currencySymbol}</span>
                  {balanceInstalmentAmount}
                </span>
                , starting <span className="lpFirstInstalmentDatePH">{scheduleStartDate}</span>
              </p>
            )}
          </div>
          {!isDeferred && (
            <div className="col-sm-4 pl-3 pl-sm-1">
              <a className="lpScheduleInstalmentsButton link style1" href="#" onClick={toggleEditSchedule}>
                Change payment dates
              </a>
            </div>
          )}
        </div>
      )}
    </>
  );

  const reviewPaySchedule =
    payPlanData?.proposedSchedule &&
    payPlanData?.proposedSchedule.map(({ amount, at }, index) => {
      return (
        <div className="col-6 payment lpFourthPay lpPayInstalment" key={at}>
          <div className="payment-type lpInstalmentCaption">{instalmentNames[index]} Payment</div>
          <div className="updatePayment lpPayInstalmentDataChange" style={{ display: 'block' }}>
            {editScheduleIndex === index ? (
              index === 0 ? (
                <>
                  <div className="form-item">
                    <label className="custom-select-list">$</label>
                    <input
                      className="amount form-input"
                      type="text"
                      name="firstInstalmentAmount"
                      value={initialPaymentAmount}
                      onChange={handleFirstInstalmentChange}
                    />
                    <div className="lpError lpInitialPaymentErrMsg" style={{ display: changeScheduleError && 'block' }}>
                      {changeScheduleError}
                    </div>
                  </div>
                  <div className="mt-3">
                    <a
                      className="savePayment lpFirstInstAmountUpdateButton"
                      href="#"
                      onClick={handleFirstInstalmentChangeConfirm}
                      style={{ color: 'var(--lp-colors-medium-blue-600)' }}
                    >
                      Save
                    </a>
                  </div>
                </>
              ) : (
                <div className="lpPayInstalmentDataChange">
                  <DatePickerWrapper>
                    <DatePicker
                      className="amount form-input"
                      ref={(el) => (itemsRef.current[index] = el)}
                      dateFormat="dd/MM/yyyy"
                      selected={index === editScheduleIndex ? selectedDate || dayjs(at).toDate() : dayjs(at).toDate()}
                      onSelect={handleDateSelect}
                      onChange={handleDateChange}
                      onBlur={(e) => handleBlur(e, proposedScheduleAt[index])}
                      shouldCloseOnSelect={false}
                      minDate={subDays(dayjs(proposedScheduleAt[index]).toDate(), 2)}
                      maxDate={addDays(dayjs(proposedScheduleAt[index]).toDate(), 2)}
                    >
                      <div
                        className="link style1"
                        style={{
                          paddingBottom: '16px',
                          paddingRight: '40px',
                          color: 'var(--lp-colors-medium-blue-600)',
                          textAlign: 'right',
                          cursor: 'pointer',
                          backgroundPosition: '94% 93%',
                        }}
                        onClick={handleConfirm}
                      >
                        Save
                      </div>
                    </DatePicker>
                  </DatePickerWrapper>
                  <a href="#" className="link d-block" onClick={handleConfirm}>
                    Save
                  </a>
                  <div style={{ color: 'red', marginTop: '10px' }}>{changeScheduleError}</div>
                </div>
              )
            ) : (
              <div className="paymentHistory lpPayInstalmentData">
                <h4 className="mPaymentDate lpInstSchedDate">{dayjs(at).format('D MMM YYYY')}</h4>
                <div className="pprice color-grey lpInstAmount">${currencyFormat(amount, params.currency)}</div>
                <a
                  className="link lpChangePayment"
                  href="#"
                  onClick={(e) => {
                    handleScheduleChange(index);
                  }}
                >
                  Change
                </a>
              </div>
            )}
          </div>
        </div>
      );
    });

  const handlePayPlanSubmitClick = async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    await onPayPlanSubmit();
  };

  return (
    <div id="lpReviewCont">
      <div className="three-p btop">
        <div className="form-inputs">
          {isEditSchedule ? (
            <div className="lpReviewPayScheduleCont">
              <a className="link style5 lpBackToPlanSummary" href="#" onClick={handlePrevious}>
                Back
              </a>
              <h1 className="title-h1">Your Payment Plan</h1>
              <div className="row payments lpPayInstalmentList">{reviewPaySchedule}</div>
            </div>
          ) : (
            <div className="lpReviewPlanSummaryCont">
              <a className="link style5 lpBackToCardPage" href="#" onClick={handleGoBack}>
                Back
              </a>
              <h1 className="title-h1">{planTitle}</h1>
              <p className="error-msg-noa" id="lpReviewErrors">
                {payPlanError}
              </p>
              {!payPlanError && isCounterOffer && (
                <div
                  style={{
                    padding: '16px 24px',
                    margin: '16px 0',
                    backgroundColor: 'rgba(255, 248, 245, 1)',
                    border: '1px solid rgba(255, 110, 68, 0.3)',
                  }}
                  data-testid="counter-offer-text"
                >
                  {!isPayInFull && !isDeferred && (
                    <>
                      <p style={{ marginBottom: 10 }}>
                        Based on our assessment of your financial history, we have devised the payment schedule below.{' '}
                        <span style={{ fontWeight: 700 }}>
                          To proceed with a payment plan, {firstInstalmentPercent} of the order total is required to be
                          paid today.
                        </span>{' '}
                        The remaining balance will be split evenly over three fortnightly payments.
                      </p>
                      <p style={{ fontSize: 11, color: '#6D6D6D' }}>
                        Successfully paying off this plan will improve your chances of being approved for future payment
                        plans.
                      </p>
                    </>
                  )}
                  {!isPayInFull && isDeferred && (
                    <>
                      <p style={{ marginBottom: 10 }}>
                        Based on our assessment of your financial history, we're unable to provide you with a
                        traditional payment plan today.{' '}
                        <span style={{ fontWeight: 700 }}>
                          To proceed with this order, {firstInstalmentPercent} of the order total is required to be paid
                          today.
                        </span>
                      </p>
                      <p style={{ fontSize: 11, color: '#6D6D6D' }}>
                        Successfully paying off this plan will improve your chances of being approved for future payment
                        plans.
                      </p>
                    </>
                  )}
                  {isPayInFull && (
                    <>
                      <p style={{ marginBottom: 10 }}>
                        Based on our assessment of your financial history, we're unable to provide you with a payment
                        plan today.{' '}
                        <span style={{ fontWeight: 700 }}>The total amount for your order is required to be paid.</span>
                      </p>
                      <p style={{ fontSize: 11, color: '#6D6D6D' }}>
                        A successful payment today will improve your chances of being approved for a payment plan in the
                        future.
                      </p>
                    </>
                  )}
                </div>
              )}
              {reviewPlanSummary}
            </div>
          )}
          <div className="agm">
            <label className="lp-option">
              <span>
                I’ve read and agree to the{' '}
                <a
                  className="link style6 d-inline-block d-sm-inline"
                  href={config.tcUrl}
                  rel="noreferrer"
                  target="_blank"
                >
                  {isPayInFull ? 'Payment Terms' : 'Payment Plan Terms'}
                </a>{' '}
                and consent to the collection, use and disclosure of my personal information in accordance with the
                April{' '}
                <a
                  className="link style6 d-inline-block d-sm-inline"
                  href={config.privacyUrl}
                  rel="noreferrer"
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </span>
              <input
                id="lpTermsAgreedCheck"
                type="checkbox"
                checked={isTermsAgreed}
                disabled={isTermsAgreed}
                onChange={handleTermsAgreeChange}
              />
              <span className="checkmark checkmark-grey"></span>
              <div id="payplanProcessingLoader"></div>
            </label>
          </div>
          <div className="form-action">
            {!!params.showPayPlanSubmit && (
              <a className="cta" id="lpCreatePayPlanButton" href="#" onClick={handlePayPlanSubmitClick}>
                Submit payment plan
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
