import 'whatwg-fetch';

import { md5 } from 'hash-wasm';
import {
  CardPaymentSource,
  CardPaymentSourceBrand,
  CardUsageScope,
  Currency,
  CustomerEligibility,
  CustomisedInstalmentSchedule,
  PayPlanVariant,
  VgsCardType,
  getApiHost,
  getErrorMessage,
} from 'lib';
import { logRocketTrack } from 'lib/logrocket';
import { v4 as uuid } from 'uuid';

export const sessionCorrelationId = uuid();

export let idempotencyKey = sessionCorrelationId;

export const toIdempotencyKey = async (values: Array<any>) => `${idempotencyKey}-${await md5(JSON.stringify(values))}`;

export type DefaultHeaders = Partial<{
  'Session-Correlation-Id': string;
  'Logrocket-Session-Url': string;
}>;

export const defaultHeaders: DefaultHeaders = {
  'Session-Correlation-Id': sessionCorrelationId,
};

export const handleResponse = async <T = any>(res: Response): Promise<T> => {
  const requestId = res.headers.get('limepay-request-id') || undefined;
  logRocketTrack({
    requestId,
  });

  let body = await res.text();

  if (res.ok) {
    // success response
    return body.length ? JSON.parse(body) : body;
  }

  // error response
  let errorMessage = body;
  try {
    // attempt to parse json error
    const payload = JSON.parse(body);
    logRocketTrack({
      statusCode: payload.statusCode,
      errorCode: payload.errorCode,
      message: payload.message,
      tracer: payload.tracer,
    });
    errorMessage = getErrorMessage(payload);
  } finally {
    // throw error message
    throw Error(errorMessage);
  }
};

export interface GetCheckoutConfigResponse {
  apiBaseUri: string;
  merchantId: string;
  marketplaceId?: string;
  tenantId: string;
  authApiKey: string;
  authDomain: string;
  googleProjectApiKey: string;
  veryGoodSecurityVaultEnvironment: 'live' | 'sandbox';
  veryGoodSecurityVaultId: string;
  tcUrl: string;
  privacyUrl: string;
  minPayPlanAmount: number;
  maxPayPlanAmountConfirmedCustomers: number;
  maxPayPlanAmountUnconfirmedCustomers: number;
  isB2B?: boolean;
  displayCustomerSavedPaymentMethodsInCheckout: boolean;
  allowHideFullPay: boolean;
  merchantTradingCountry: 'AU' | 'NZ';
  payPlanVariant: PayPlanVariant;
  supportedCustomerTypes: Array<'consumercustomertype' | 'organisationcustomertype'>;
  merchantBusinessName: string;
  merchantBusinessDisplayName: string;
  merchantTags?: Array<'DirectDebit'>;
  marketplaceTags?: Array<'DirectDebit'>;
  cardinalDdcJwt?: string;
  allowedCardBrands?: Array<CardPaymentSourceBrand>;
}
export const getCheckoutConfig = async (host: string, publicKey: string): Promise<GetCheckoutConfigResponse> => {
  const apiHost = await getApiHost();

  return fetch(`${apiHost}/config/checkout`, {
    method: 'GET',
    headers: new Headers({
      ...defaultHeaders,
      'Limepay-PublicKey': publicKey,
    }),
  }).then(handleResponse);
};

export interface CardPaymentMethod {
  cardNumber: string;
  cardCvc: string;
  expiryDate: {
    expiryMonth: string;
    expiryYear: string;
  };
  last4?: string;
  cardType?: VgsCardType;
  bin?: string;
}
export interface VeryGoodSecurityPaymentMethodToken {
  cardPaymentMethod: CardPaymentMethod;
  createdAt: string;
}

export interface CreateOrGetCustomerRequest {
  emailAddress: string;
  phoneNumber: string;
}
export interface SendCustomerVerificationRequest {
  emailAddress: string;
  phoneNumber: string;
  mode: 'Both' | 'Email' | 'Phone';
}
export interface VerifyCustomerRequest {
  customerId: string;
  emailVerification: SendCustomerVerification['emailVerification'];
  phoneVerification: SendCustomerVerification['phoneVerification'];
  emailVerificationCode?: string;
  phoneVerificationCode: string;
}
export interface AddPaymentSourceRequest {
  veryGoodSecurityPaymentMethodToken: VeryGoodSecurityPaymentMethodToken;
}
export interface SendCustomerVerification {
  emailVerification: {
    customerId: string;
    emailAddress: string;
    createdAt: string;
  } | null;
  phoneVerification: { customerId: string; phoneNumber: string; createdAt: string };
}
export interface VerifyCustomer {
  customToken: string;
  error: string;
}

export interface CreatePayCardRequest {
  paymentAmount: {
    amount: number;
    currency: Currency;
  };
  tokenizedPaymentSource: {
    veryGoodSecurityPaymentMethodToken: VeryGoodSecurityPaymentMethodToken;
  };
  customerId?: string;
  cardCvc: string;
  threeDsSessionId: string | null;
}
export interface CreatePayCardResponse {
  paymentTokenId?: string;
  payCardId: string;
}
export const createPayCard = async (
  host: string,
  publicKey: string,
  payload: CreatePayCardRequest,
): Promise<CreatePayCardResponse> =>
  fetch(`${host}/payments/card`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: new Headers({
      ...defaultHeaders,
      'Limepay-PublicKey': publicKey,
    }),
  }).then(handleResponse);

export interface CreatePayCardJWT {
  paymentAmount: {
    amount: number;
    currency: Currency;
  };
  cardPaymentSourceId: string;
  customerId?: string;
  cardCvc: string;
  threeDsSessionId: string | null;
}

export const createPayCardJWT = async (
  host: string,
  customerIdToken: string,
  payload: CreatePayCardJWT,
): Promise<CreatePayCardResponse> =>
  fetch(`${host}/payments/card-secured`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: new Headers({
      ...defaultHeaders,
      Authorization: `Bearer ${customerIdToken}`,
    }),
  }).then(handleResponse);

export const createOrGetCustomer = (
  host: string,
  publicKey: string,
  payload: CreateOrGetCustomerRequest,
): Promise<string> =>
  fetch(`${host}/customers`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: new Headers({
      ...defaultHeaders,
      'Limepay-PublicKey': publicKey,
    }),
  }).then(handleResponse);

export interface GetCustomerResponse {
  customerId: string;
  merchantId: string;
  firstName: string;
  lastName: string;
  email: string;
  customerAccountId: string;
  notificationSettings?: {
    makePurchase: {
      email: boolean;
      sms: boolean;
    };
    successfulPayment: {
      email: boolean;
      sms: boolean;
    };
    unsuccessfulPayment: {
      email: boolean;
      sms: boolean;
    };
  };
  stripeCustomerId?: string;
  isEmailVerified: boolean;
  isVerified: boolean;
  phoneNumber?: string;
  defaultSourceId?: string;
  primarySourceId?: string;
  createdAt: string;
  updatedAt: string;
}
export const getCustomer = (host: string, customerId: string, customerIdToken: string): Promise<GetCustomerResponse> =>
  fetch(`${host}/customers/${customerId}`, {
    method: 'GET',
    headers: new Headers({
      ...defaultHeaders,
      Authorization: `Bearer ${customerIdToken}`,
    }),
  }).then(handleResponse);

export const sendCustomerVerification = (
  host: string,
  publicKey: string,
  customerId: string,
  payload: SendCustomerVerificationRequest,
): Promise<SendCustomerVerification> =>
  fetch(`${host}/customers/${customerId}/verification`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: new Headers({
      ...defaultHeaders,
      'Limepay-PublicKey': publicKey,
    }),
  }).then(handleResponse);

export const verifyCustomer = (
  host: string,
  publicKey: string,
  payload: VerifyCustomerRequest,
): Promise<VerifyCustomer> =>
  fetch(`${host}/customers/${payload.customerId}/verification/codes`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: new Headers({
      ...defaultHeaders,
      'Limepay-PublicKey': publicKey,
    }),
  }).then(handleResponse);

export interface GetPayPlanEligibilityCheckRequest {
  customerId: string;
  planAmount: {
    amount: number;
    currency: Currency;
  };
  initialPayment: number;
  instalmentLength: number;
  instalmentFrequency: string;
  customisedInstalmentSchedule?: CustomisedInstalmentSchedule;
}
export interface GetPayPlanEligibilityCheckResponse {
  customerPayPlanEligibilityOutcome: CustomerEligibility;
  verificationToken: string;
}
export const getPayPlanEligibilityCheck = (
  host: string,
  publicKey: string,
  payload: GetPayPlanEligibilityCheckRequest,
): Promise<GetPayPlanEligibilityCheckResponse> =>
  fetch(`${host}/payments/plan/eligibility-check`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: new Headers({
      ...defaultHeaders,
      'Limepay-PublicKey': publicKey,
    }),
  })
    .then(handleResponse)
    .then((response: GetPayPlanEligibilityCheckResponse) => {
      const { offerPayPlan, message } = response.customerPayPlanEligibilityOutcome;

      // deny
      if (!offerPayPlan) {
        return Promise.reject(message || "Sorry, we weren't able to determine your eligibility");
      }

      return response;
    });

export type QueryCardPaymentSourcesResponse = Array<CardPaymentSource>;
export const queryCardPaymentSources = (
  host: string,
  customerId: string,
  customerIdToken: string,
): Promise<QueryCardPaymentSourcesResponse> =>
  fetch(`${host}/customers/${customerId}/payments/sources/card?sort=-updatedAt`, {
    method: 'GET',
    headers: new Headers({
      ...defaultHeaders,
      Authorization: `Bearer ${customerIdToken}`,
    }),
  }).then(handleResponse);

export const addCustomerPaymentSource = (
  host: string,
  customerId: string,
  customerIdToken: string,
  payload: AddPaymentSourceRequest,
): Promise<CardPaymentSource> =>
  fetch(`${host}/customers/${customerId}/payments/sources`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: new Headers({
      ...defaultHeaders,
      Authorization: `Bearer ${customerIdToken}`,
      'Content-Type': 'application/json',
    }),
  }).then(handleResponse);

export const addTemporaryPaymentSource = (
  host: string,
  publicKey: string,
  payload: AddPaymentSourceRequest,
): Promise<CardPaymentSource> =>
  fetch(`${host}/payments/sources`, {
    method: 'POST',
    body: JSON.stringify({
      tokenizedPaymentSource: payload,
    }),
    headers: new Headers({
      ...defaultHeaders,
      'Limepay-PublicKey': publicKey,
      'Content-Type': 'application/json',
    }),
  }).then(handleResponse);

export interface CreatePayPlanRequest {
  customerId: string;
  cardPaymentSourceId: string;
  customerPayPlanEligibilityOutcome: CustomerEligibility;
  verificationToken: string;
  integrationCanHandle3DSOnPayOrder?: boolean;
  cardCvc: string;
  threeDsSessionId: string | null;
}
export interface CreatePayPlanResponse {
  paymentTokenId?: string;
  payPlanId: string;
}
export const createPayPlan = async (
  host: string,
  publicKey: string,
  customerIdToken: string,
  payload: CreatePayPlanRequest,
): Promise<CreatePayPlanResponse> =>
  fetch(`${host}/payments/plan`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: new Headers({
      ...defaultHeaders,
      Authorization: `Bearer ${customerIdToken}`,
      'Limepay-PublicKey': publicKey,
      'Content-Type': 'application/json',
    }),
  }).then(handleResponse);

export const addCustomerPaymentSourceScoped = (
  host: string,
  customerId: string,
  customerIdToken: string,
  veryGoodSecurityPaymentMethodToken: VeryGoodSecurityPaymentMethodToken,
  cardUsageScope: CardUsageScope,
): Promise<CardPaymentSource> =>
  fetch(`${host}/customers/${customerId}/payments/sources/scoped`, {
    method: 'POST',
    body: JSON.stringify({
      tokenizedPaymentSource: {
        veryGoodSecurityPaymentMethodToken,
      },
      cardUsageScope,
    }),
    headers: new Headers({
      ...defaultHeaders,
      Authorization: `Bearer ${customerIdToken}`,
      'Content-Type': 'application/json',
    }),
  }).then(handleResponse);

export const updateCustomerPaymentSourceCvc = (
  host: string,
  customerId: string,
  customerIdToken: string,
  cardPaymentSourceId: string,
  cardCvc: string,
): Promise<void> =>
  fetch(`${host}/customers/${customerId}/payments/sources/cvc`, {
    method: 'POST',
    body: JSON.stringify({
      cardPaymentSourceId,
      cardCvc,
    }),
    headers: new Headers({
      ...defaultHeaders,
      Authorization: `Bearer ${customerIdToken}`,
      'Content-Type': 'application/json',
    }),
  }).then(handleResponse);
