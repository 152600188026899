import { SendCustomerVerification } from 'app';
import { cx } from 'emotion';
import { CheckoutStep, getErrorMessage, useSendEmailAndPhoneVerificationCodes, validateNonEmptyStr } from 'lib';
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

export interface UserVerificationProps {
  customerId: string;
  email: string;
  phoneNo: string;
  emailVerification: SendCustomerVerification['emailVerification'];
  phoneVerification: SendCustomerVerification['phoneVerification'];
  emailVerificationCode: string;
  phoneVerificationCode: string;
  setEmailVerificationCode(value: string): void;
  setPhoneVerificationCode(value: string): void;
  checkoutStep: number;
  setCheckoutStep(value: CheckoutStep): void;
  onVerificationCodesSubmit(
    customerId: string,
    emailVerification: SendCustomerVerification['emailVerification'],
    phoneVerification: SendCustomerVerification['phoneVerification'],
    emailVerificationCode: string,
    phoneVerificationCode: string,
  ): Promise<{ customerError?: string; customToken?: string }>;
  setPending(pending: boolean): void;
  onSuccess?(): void;
}

export const UserVerification: React.FC<UserVerificationProps> = ({
  customerId,
  email,
  phoneNo,
  emailVerification,
  phoneVerification,
  emailVerificationCode,
  phoneVerificationCode,
  setEmailVerificationCode,
  setPhoneVerificationCode,
  checkoutStep,
  setCheckoutStep,
  onVerificationCodesSubmit,
  setPending,
  onSuccess,
}) => {
  const sendEmailAndPhoneVerificationCodes = useSendEmailAndPhoneVerificationCodes();

  const [isInteracted, setIsInteracted] = React.useState<boolean>(false);
  const [isValidEmailVerificationCode, setIsValidEmailVerificationCode] = React.useState<boolean>(!emailVerification);
  const [isValidPhoneVerificationCode, setIsValidPhoneVerificationCode] = React.useState<boolean>(false);
  const [verifyCodeError, setVerifyCodeError] = React.useState<string>('');
  const [successMessage, setSuccessMessage] = React.useState<string>('');

  const handleVerifyCodes = React.useCallback(async () => {
    let isValid = false;
    setSuccessMessage('');

    if (!isInteracted) {
      const isValidEmailCode = emailVerification
        ? validateNonEmptyStr(emailVerificationCode) && emailVerificationCode.length === 6
        : true;
      const isValidPhoneCode = validateNonEmptyStr(phoneVerificationCode) && phoneVerificationCode.length === 6;
      setIsValidEmailVerificationCode(isValidEmailCode);
      setIsValidPhoneVerificationCode(isValidPhoneCode);
      setIsInteracted(true);
      isValid = isValidEmailCode && isValidPhoneCode;
    } else {
      isValid = isValidEmailVerificationCode && isValidPhoneVerificationCode;
    }

    if (isValid) {
      const { customerError, customToken } = await onVerificationCodesSubmit(
        customerId,
        emailVerification,
        phoneVerification,
        emailVerificationCode,
        phoneVerificationCode,
      );

      if (customerError) {
        //TODO Get and display specific email/sms error source after API is ready
        setVerifyCodeError(customerError);
      }

      if (!customerError && customToken) {
        setPending(true);
        setVerifyCodeError('');
        try {
          await onSuccess?.();
        } catch (error) {
          setVerifyCodeError(getErrorMessage(error));
        } finally {
          setPending(false);
        }
      }
    } else {
      return false;
    }
  }, [
    customerId,
    emailVerification,
    phoneVerification,
    emailVerificationCode,
    phoneVerificationCode,
    isInteracted,
    isValidEmailVerificationCode,
    isValidPhoneVerificationCode,
    onVerificationCodesSubmit,
    setPending,
    onSuccess,
  ]);

  // Resends verification codes via both phone and email, different than lime-web
  const handleResendCodes = React.useCallback(async () => {
    setPhoneVerificationCode('');
    setEmailVerificationCode('');
    setIsValidEmailVerificationCode(true);
    setIsValidPhoneVerificationCode(true);
    setVerifyCodeError('');
    setSuccessMessage('');
    try {
      await sendEmailAndPhoneVerificationCodes(email, phoneNo, customerId);
      setSuccessMessage('Codes resent successfully');
    } catch (error) {
      setVerifyCodeError(getErrorMessage(error));
    }
  }, [
    customerId,
    email,
    phoneNo,
    setPhoneVerificationCode,
    setEmailVerificationCode,
    sendEmailAndPhoneVerificationCodes,
    setIsValidEmailVerificationCode,
    setIsValidPhoneVerificationCode,
  ]);

  const handleGoBack = (e: React.MouseEvent<HTMLAnchorElement>) => {
    setCheckoutStep(CheckoutStep.PaymentOption);
  };

  const handleEmailVerificationCodeChange = React.useCallback(
    (e) => {
      const verificationInputValue = e.target.value;
      setEmailVerificationCode(verificationInputValue);
      setIsValidEmailVerificationCode(
        validateNonEmptyStr(verificationInputValue) && verificationInputValue.length === 6,
      );
    },
    [setEmailVerificationCode, setIsValidEmailVerificationCode],
  );

  const handlePhoneVerificationCodeChange = React.useCallback(
    (e) => {
      const verificationInputValue = e.target.value;
      setPhoneVerificationCode(verificationInputValue);
      setIsValidPhoneVerificationCode(
        validateNonEmptyStr(verificationInputValue) && verificationInputValue.length === 6,
      );
    },
    [setPhoneVerificationCode, setIsValidPhoneVerificationCode],
  );

  return (
    <div id="lpVerificationCont">
      <div className="three-p btop">
        <div className="form-inputs">
          <h1 className="title-h1">Did you receive the {emailVerification ? 'codes' : 'code'}?</h1>
          <p className="small-text add-bb" id="lpVerificationMessage" data-private>
            We've sent {emailVerification ? `codes to ${phoneNo} and ${email}` : `a code to ${phoneNo}`}
          </p>
          <p className="error-msg-noa" id="lpVerificationErrors">
            {verifyCodeError}
          </p>
          {!!successMessage && <p className="text-success">{successMessage}</p>}
          <div className="row">
            <div className={`col-sm-${emailVerification ? '6' : '12'}`}>
              <div
                className={cx(
                  'form-item lpVerifPhoneErrorMsgCont',
                  isInteracted && !isValidPhoneVerificationCode && 'error',
                )}
              >
                <input
                  className="phone form-input"
                  id="lpPhoneNoVerifCode"
                  name="phoneNoVerifCode"
                  type="numeric"
                  placeholder="Mobile code"
                  autoComplete="off"
                  value={phoneVerificationCode}
                  onChange={handlePhoneVerificationCodeChange}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  maxLength={6}
                />
                <p className="error-msg lpVerifPhoneErrorMsg">Please enter a valid code</p>
              </div>
            </div>
            {!!emailVerification && (
              <div className="col-sm-6 lpEmailVerifCodeCont">
                <div
                  className={cx(
                    'form-item lpVerifEmailErrorMsgCont',
                    isInteracted && !isValidEmailVerificationCode && 'error',
                  )}
                >
                  <input
                    className="email form-input"
                    id="lpEmailVerifCode"
                    name="emailVerifCode"
                    type="numeric"
                    placeholder="Email code"
                    autoComplete="off"
                    value={emailVerificationCode}
                    onChange={handleEmailVerificationCodeChange}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    maxLength={6}
                  />
                  <p className="error-msg lpVerifEmailErrorMsg">Please enter a valid code</p>
                </div>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-sm-6">
              <a className="lpResendCodes link style2" href="#" onClick={handleResendCodes}>
                Resend {emailVerification ? 'Codes' : 'Code'}
              </a>
            </div>
          </div>
        </div>
        <div className="form-action text-center">
          <a className="cta" id="lpVerificationSubmitButton" href="#" onClick={handleVerifyCodes}>
            Confirm
          </a>
          <a className="link style3" id="lpRollbackVericationButton" href="#" onClick={handleGoBack}>
            Go back
          </a>
        </div>
      </div>
    </div>
  );
};
