import {
  CARD_ERROR_MESSAGE,
  CardPaymentSourceBrand,
  VgsCardError,
  VgsCardType,
  getApiHost,
  getApiHostEnv,
  getAprilHost,
  getErrorMessage,
} from 'lib';
import LogRocket from 'logrocket';

import { VaultCardType, VaultError, VaultFieldStyles, VaultSubmitResponse, VaultValidationResult } from '@april/lib-ui';

export const cardNumberId = 'cardNumberCont';
export const cardExpiryId = 'cardExpiryCont';
export const cardCvcId = 'cardCvcCont';
export const cardCvcOnlyId = 'cardCvcOnlyCont';

export const getVaultHost = async () => `https://vault.${getAprilHost(getApiHostEnv(await getApiHost()))}`;
export const getVaultApiHost = async () => `https://api-vault.${getAprilHost(getApiHostEnv(await getApiHost()))}`;

export const fieldStyles: VaultFieldStyles = {
  fontSize: '12.5px',
  '&:focus': {
    color: '#000000',
  },
  '&::placeholder': {
    color: '#8C95A8',
  },
  '&:focus::placeholder': {
    color: '#8C95A8',
  },
  '&.isInvalid': { color: '#D3354A' },
  '&.isValid': { color: '#4F8A10' },
};

export const toVgsCardType = (cardType: VaultCardType): VgsCardType | undefined =>
  cardType === 'american-express'
    ? 'amex'
    : cardType === 'diners-club'
    ? 'dinersclub'
    : cardType === 'hiper'
    ? undefined
    : cardType === 'hipercard'
    ? undefined
    : cardType === 'mir'
    ? undefined
    : cardType;

export const toCardError = (errors: VaultValidationResult['errors']): VgsCardError => ({
  formError: CARD_ERROR_MESSAGE,
  cardNumberError: errors.cardNumber,
  expiryDateError: errors.expiryDate,
  cardCvcError: errors.cardCvc,
});

export const onVaultError = ({ errorMessage, field }: VaultError) =>
  LogRocket.captureMessage(`Vault Error: ${errorMessage}`, {
    extra: {
      fieldType: field.type,
    },
  });

export const handleVaultSubmitError = ({ error, response }: VaultSubmitResponse) => {
  if (error || !response) {
    LogRocket.captureMessage(`Vault Submit Error: ${getErrorMessage(error)}`);
    throw Error('There was a temporary network error, please try again.');
  }
  return { response };
};

export const VaultCardTypeToBrand: { [type: string]: CardPaymentSourceBrand } = {
  'american-express': 'American Express',
  'diners-club': 'Diners Club',
  discover: 'Discover',
  elo: 'Elo',
  hiper: 'Hiper',
  hipercard: 'Hipercard',
  jcb: '',
  maestro: 'Maestro',
  mastercard: 'MasterCard',
  mir: 'Mir',
  unionpay: 'UnionPay',
  visa: 'Visa',
};

export const toCardPaymentSourceBrand = (cardType: VaultCardType): CardPaymentSourceBrand =>
  VaultCardTypeToBrand.hasOwnProperty(cardType) ? VaultCardTypeToBrand[cardType] : '';
